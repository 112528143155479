export default function validate(
  value,
  segduration,
  spkid,
  //segmentIstranscribe,
  lan
) {
  let errors = [];
  let segmentdata = [];

  //check Blank segment
  //console.log("segmentIstranscribed", value, segduration,spkid, segmentIstranscribe)
  if(!value || value == "" || value == " "){
      let SegmentDataEmpty = "Segment data is empty";
      segmentdata.push({ SegmentDataEmpty: SegmentDataEmpty });
  }

  //more than 20 seconds check on segment length
  //debugger;
  if (segduration >= 20) {
    let TwentySecondError =
      "Segment is of more than twenty seconds duration, please split into smaller segments";
    segmentdata.push({ TwentySecondError: TwentySecondError });
  }

  //Check Space before period
  let spaceperiodregex = /\s[.|?|!]/;
  let SpaceBeforePeriod = spaceperiodregex.test(value);
  if (SpaceBeforePeriod) {
    SpaceBeforePeriod = "Space found before punctuation mark";
    segmentdata.push({ SpaceBeforePeriod: SpaceBeforePeriod });
  }

  // //Remove new line character
  // let removeNewlineCharRegex = value.replace(/\n/g, '')

  //Numerice value in text-area check
 // debugger;
  let numberregex = /[०-९]|[0-9]|[০-৯]|[૦-૯]|[೦-೯]|[൦-൯]|[୦-୯]|[੦-੯]|[௦-௯]|[౦-౯]|[۰-۹]/g
  //let numberregex = /\d/;
  let Overlapregex=/<OVERLAP\s*\S\d*>/g;
  let tempval=value;
  //console.log("tempval..",tempval);
  tempval=tempval.replace(Overlapregex,"");
  let numericValue = numberregex.test(tempval);
  
  if (numericValue) {
    //let Overlap=/\W\d/;
    numericValue = "Number in digits found";
    segmentdata.push({ numericValue: numericValue });
  }

  ////Special characters check
  //.!?,;:
  //debugger;
  //
  let newtext=value.replaceAll("<SIL>","").replaceAll("<SEGMENT>","").replaceAll("<UNKNOWN_SEGMENT>","").replaceAll("<PAUSE>","").replaceAll("<dog barking>","").replaceAll("</dog barking>","").replaceAll("<baby talking>","").replaceAll("</baby talking>","").replaceAll("<baby crying>","").replaceAll("</baby crying>","").replaceAll("<animal sound>","").replaceAll("</animal sound>","").replaceAll("<vehicle noise>","").replaceAll("</vehicle noise>","").replaceAll("<bird sound>","").replaceAll("</bird sound>","").replaceAll("<people talking>","").replaceAll("</people talking>","").replaceAll("<static noise>","").replaceAll("</static noise>","").replaceAll("[inaudible]","").replaceAll("[unintelligible]", "").replaceAll("[lip smacks]", "").replaceAll("[breathing]", "").replaceAll("[child_crying]", "").replaceAll("[child_laughing]", "").replaceAll("[child_talking]", "").replaceAll("[click]", "").replaceAll("[barking]", "").replaceAll("[beep]", "").replaceAll("[coughing]", "").replaceAll("[horn]", "").replaceAll("[laughter]", "").replaceAll("[motorcycle]", "").replaceAll("[music]", "").replaceAll("[noise]", "").replaceAll("[ringing]", "").replaceAll("[sneezing]", "").replaceAll("[talking]", "").replaceAll("[throat_clearing]", "").replaceAll("[baby_crying]", "").replaceAll("[baby_talking]", "")
  .replaceAll("[inhale]", "").replaceAll("[exhale]", "").replaceAll("[alarms]", "").replaceAll("[fan_whirring]", "").replaceAll("[engine_running]", "").replaceAll("[water_flowing]", "").replaceAll("[yawning]", "")
  .replaceAll("[tongue_clicks]", "").replaceAll("[door_closing]", "").replaceAll("[typing]", "").replaceAll("[clapping]", "").replaceAll("[whispering]", "").replaceAll("[bird_sound]", "")
  .replaceAll("<noise>", "").replaceAll("</noise>", "").replaceAll("<talking>", "").replaceAll("</talking>", "").replaceAll("<coughing>", "").replaceAll("</coughing>", "").replaceAll("<honking>", "").replaceAll("</honking>", "")
  .replaceAll("<laughing>", "").replaceAll("</laughing>", "").replaceAll("<crying>", "").replaceAll("</crying>", "").replaceAll("<music sound>", "").replaceAll("</music sound>", "").replaceAll("<phone ringing>", "").replaceAll("</phone ringing>", "")
  .replaceAll("<insect noise>", "").replaceAll("</insect noise>", "").replaceAll("<bird squawk>", "").replaceAll("</bird squawk>", "").replaceAll("<fan whirring>", "").replaceAll("</fan whirring>", "").replaceAll("<engine running>", "").replaceAll("</engine running>", "")
  .replaceAll("<water flowing>", "").replaceAll("</water flowing>", "").replaceAll("<child laughing>", "").replaceAll("</child laughing>", "").replaceAll("<child talking>", "").replaceAll("</child talking>", "").replaceAll("<child crying>", "").replaceAll("</child crying>", "").replaceAll("<train sound>", "").replaceAll("</train sound>", "")
  .replaceAll("<loudspeaker noise>", "").replaceAll("</loudspeaker noise>", "").replaceAll("<whispering>", "").replaceAll("</whispering>", "").replaceAll("<typing>", "").replaceAll("</typing>", "").replaceAll("<singing>", "").replaceAll("</singing>", "");

  let specialcharactersregex = /[\@#$%^&*()":\/`~;\[\]]/g
  let SpecialCharacters = specialcharactersregex.test(newtext);

  if (SpecialCharacters) {
    if (!value.match(/([\,.'!?|{}\-]+[\\]+\S*)/)) {
      SpecialCharacters = "Special characters found.";
      segmentdata.push({ SpecialCharacters: SpecialCharacters });
    }
  }

  ////Math symbols check
  //.!?,;:
  //debugger;
  //
  let mathsymbolssregex = /[\+\=]/g;
  let MathSymbols = mathsymbolssregex.test(value);

  if (MathSymbols) {
    MathSymbols = "Math symbols found.";
    segmentdata.push({ MathSymbols: MathSymbols });
  }

  // okay check
  // if (value.match("Okay") || value.match("okay") || value.match("OKAY")) {
  //   let Okaystring = "Invalid string 'okay' found. Please repalce it with 'OK'";
  //   segmentdata.push({ Okaystring: Okaystring });
  // }

  //debugger;
  //check openCloseMismatch
  let DOGBARKopen = 0,
    DOGBARKclose = 0,
    BABYTALKopen = 0,
    BABYTALKclose = 0,
    BABYCRYopen = 0,
    BABYCRYclose = 0,
    ANIMALSOUNDopen = 0,
    ANIMALSOUNDclose = 0,
    VEHICLENOISEopen = 0,
    VEHICLENOISEclose = 0,
    BIRDSOUNDopen = 0,
    BIRDSOUNDclose = 0,
    PEOPLETALKopen = 0,
    PEOPLETALKclose = 0,
    STATICNOISEopen = 0,
    STATICNOISEclose = 0,
    noiseopen=0,
    noiseclose=0,
    talkingopen=0,
    talkingclose=0,
    coughingopen=0,
    coughingclose=0,
    honkingopen=0,
    honkingclose=0,
    laughingopen=0,
    laughingclose=0,
    cryingopen=0,
    cryingclose=0,
    musicsoundopen=0,
    musicsoundclose=0,
    phoneringingopen=0,
    phoneringingclose=0,
    insectnoiseopen=0,
    insectnoiseclose=0,
    birdsquawkopen=0,
    birdsquawkclose=0,
    fanwhirringopen=0,
    fanwhirringclose=0,
    enginerunningopen=0,
    enginerunningclose=0,
    waterflowingopen=0,
    waterflowingclose=0,
    childlaughingopen=0,
    childlaughingclose=0,
    childtalkingopen=0,
    childtalkingclose=0,
    childcryingopen=0,
    childcryingclose=0,
    trainsoundopen=0,
    trainsoundclose=0,
    loudspeakernoiseopen=0,
    loudspeakernoiseclose=0,
    whisperingopen=0,
    whisperingclose=0,
    typingopen=0,
    typingclose=0,
    singingopen=0,
    singingclose=0,

    FILErrorFlag = false;

  let openCloseMismatchRegex = /[<]+\S.*?[>]/g;
  let getopenCloseString = value;
  let openCloseMismatch = openCloseMismatchRegex.test(value);
  const match = getopenCloseString.match(openCloseMismatchRegex);

  // console.log("value..",value);
  // console.log("openCloseMismatch..",openCloseMismatch);
  // console.log("match..",match);
  
  if (openCloseMismatch) {
    //debugger;
    match.map((match) => {
      if (getopenCloseString.includes("dog barking")) {
        if (match.includes("/dog barking")) {
          DOGBARKclose++;
        } else if (match.includes("dog barking")) {
          DOGBARKopen++;
        }
      }
      if (getopenCloseString.includes("baby talking")) {
        if (match.includes("/baby talking")) {
          BABYTALKclose++;
        } else if (match.includes("baby talking")) {
          BABYTALKopen++;
        }
      }
      if (getopenCloseString.includes("baby crying")) {
        if (match.includes("/baby crying")) {
          BABYCRYclose++;
        } else if (match.includes("baby crying")) {
          BABYCRYopen++;
        }
      }
      if (getopenCloseString.includes("animal sound")) {
        if (match.includes("/animal sound")) {
          ANIMALSOUNDclose++;
        } else if (match.includes("animal sound")) {
          ANIMALSOUNDopen++;
        }
      }
      if (getopenCloseString.includes("vehicle noise")) {
        if (match.includes("/vehicle noise")) {
          VEHICLENOISEclose++;
        } else if (match.includes("vehicle noise")) {
          VEHICLENOISEopen++;
        }
      }
      if (getopenCloseString.includes("bird sound")) {
        if (match.includes("/bird sound")) {
          BIRDSOUNDclose++;
        } else if (match.includes("bird sound")) {
          BIRDSOUNDopen++;
        }
      }
      if (getopenCloseString.includes("people talking")) {
        if (match.includes("/people talking")) {
          PEOPLETALKclose++;
        } else if (match.includes("people talking")) {
          PEOPLETALKopen++;
        }
      }
      if (getopenCloseString.includes("static noise")) {
        if (match.includes("/static noise")) {
          STATICNOISEclose++;
        } else if (match.includes("static noise")) {
          STATICNOISEopen++;
        }
      }
      if (getopenCloseString.includes("<noise>")) {
        if (match.includes("</noise>")) {
          noiseclose++;
        } else if (match.includes("<noise>")) {
          noiseopen++;
        }
      }
      if (getopenCloseString.includes("<talking>")) {
        if (match.includes("</talking>")) {
          talkingclose++;
        } else if (match.includes("<talking>")) {
          talkingopen++;
        }
      }
      if (getopenCloseString.includes("coughing")) {
        if (match.includes("/coughing")) {
          coughingclose++;
        } else if (match.includes("coughing")) {
          coughingopen++;
        }
      }
      if (getopenCloseString.includes("honking")) {
        if (match.includes("/honking")) {
          honkingclose++;
        } else if (match.includes("honking")) {
          honkingopen++;
        }
      }
      if (getopenCloseString.includes("<laughing>")) {
        if (match.includes("</laughing>")) {
          laughingclose++;
        } else if (match.includes("<laughing>")) {
          laughingopen++;
        }
      }
      if (getopenCloseString.includes("<crying>")) {
        if (match.includes("</crying>")) {
          cryingclose++;
        } else if (match.includes("<crying>")) {
          cryingopen++;
        }
      }
      if (getopenCloseString.includes("music sound")) {
        if (match.includes("/music sound")) {
          musicsoundclose++;
        } else if (match.includes("music sound")) {
          musicsoundopen++;
        }
      }
      if (getopenCloseString.includes("phone ringing")) {
        if (match.includes("/phone ringing")) {
          phoneringingclose++;
        } else if (match.includes("phone ringing")) {
          phoneringingopen++;
        }
      }
      if (getopenCloseString.includes("insect noise")) {
        if (match.includes("/insect noise")) {
          insectnoiseclose++;
        } else if (match.includes("insect noise")) {
          insectnoiseopen++;
        }
      }
      if (getopenCloseString.includes("bird squawk")) {
        if (match.includes("/bird squawk")) {
          birdsquawkclose++;
        } else if (match.includes("bird squawk")) {
          birdsquawkopen++;
        }
      }
      if (getopenCloseString.includes("fan whirring")) {
        if (match.includes("/fan whirring")) {
          fanwhirringclose++;
        } else if (match.includes("fan whirring")) {
          fanwhirringopen++;
        }
      }
      if (getopenCloseString.includes("engine running")) {
        if (match.includes("/engine running")) {
          enginerunningclose++;
        } else if (match.includes("engine running")) {
          enginerunningopen++;
        }
      }
      if (getopenCloseString.includes("water flowing")) {
        if (match.includes("/water flowing")) {
          waterflowingclose++;
        } else if (match.includes("water flowing")) {
          waterflowingopen++;
        }
      }
      if (getopenCloseString.includes("child laughing")) {
        if (match.includes("/child laughing")) {
          childlaughingclose++;
        } else if (match.includes("child laughing")) {
          childlaughingopen++;
        }
      }
      if (getopenCloseString.includes("child talking")) {
        if (match.includes("/child talking")) {
          childtalkingclose++;
        } else if (match.includes("child talking")) {
          childtalkingopen++;
        }
      }
      if (getopenCloseString.includes("child crying")) {
        if (match.includes("/child crying")) {
          childcryingclose++;
        } else if (match.includes("child crying")) {
          childcryingopen++;
        }
      }
      if (getopenCloseString.includes("train sound")) {
        if (match.includes("/train sound")) {
          trainsoundclose++;
        } else if (match.includes("train sound")) {
          trainsoundopen++;
        }
      }
      if (getopenCloseString.includes("loudspeaker noise")) {
        if (match.includes("/loudspeaker noise")) {
          loudspeakernoiseclose++;
        } else if (match.includes("loudspeaker noise")) {
          loudspeakernoiseopen++;
        }
      }
      if (getopenCloseString.includes("whispering")) {
        if (match.includes("/whispering")) {
          whisperingclose++;
        } else if (match.includes("whispering")) {
          whisperingopen++;
        }
      }
      if (getopenCloseString.includes("typing")) {
        if (match.includes("/typing")) {
          typingclose++;
        } else if (match.includes("typing")) {
          typingopen++;
        }
      }
      if (getopenCloseString.includes("singing")) {
        if (match.includes("/singing")) {
          singingclose++;
        } else if (match.includes("singing")) {
          singingopen++;
        }
      }
            
    });

    if (
      DOGBARKopen != DOGBARKclose || BABYTALKopen != BABYTALKclose || BABYCRYopen != BABYCRYclose || ANIMALSOUNDopen != ANIMALSOUNDclose || VEHICLENOISEopen != VEHICLENOISEclose || BIRDSOUNDopen != BIRDSOUNDclose || PEOPLETALKopen != PEOPLETALKclose || STATICNOISEopen != STATICNOISEclose
      ||noiseopen!=noiseclose||talkingopen!=talkingclose||coughingopen!=coughingclose||honkingopen!=honkingclose||laughingopen!=laughingclose
      ||cryingopen!=cryingclose||musicsoundopen!=musicsoundclose||phoneringingopen!=phoneringingclose||insectnoiseopen!=insectnoiseclose
      ||birdsquawkopen!=birdsquawkclose||fanwhirringopen!=fanwhirringclose||enginerunningopen!=enginerunningclose
      ||waterflowingopen!=waterflowingclose||childlaughingopen!=childlaughingclose||childtalkingopen!=childtalkingclose||childcryingopen!=childcryingclose
      ||trainsoundopen!=trainsoundclose||loudspeakernoiseopen!=loudspeakernoiseclose||whisperingopen!=whisperingclose
      ||typingopen!=typingclose||singingopen!=singingclose
    ) {
      openCloseMismatch = "Open close Angular/Curly tag mismatch found.";
      segmentdata.push({ openCloseMismatch: openCloseMismatch });
    }

    let angularemptyexpr = /[<]\S*[>]\s*[<]\S*[>]/g;
    // |[<]\S*[/>]
    //let spaceperiodregex = new RegExp(/(\s)\./);
    let emptyangulartags = angularemptyexpr.test(value);
    if (emptyangulartags) {
      let noerrorexpr = /[<\/]\S*[>]\s*[<]\S*[>]/g;
      if (!noerrorexpr) {
        emptyangulartags = "Empty angular tags found";
        segmentdata.push({ EmptyAngularTags: emptyangulartags });
      }
    }
  }

  ///validation for misplaced tags, close tag comes first
  let ntext = value.replaceAll("<SIL>","").replaceAll("<SEGMENT>","").replaceAll("<UNKNOWN_SEGMENT>","").replaceAll("<PAUSE>",""); 
  //let openClosematch = openCloseMismatchRegex.test(value);
  const matches = ntext.match(openCloseMismatchRegex);
  console.log("matches..",matches);
  let OC = [];
    
  if(matches && matches.length>0)
  {
    if(lan == "Urdu"){
      matches.map((match) => {
        //console.log("match", match)
        // if (match.includes("/")) {
        //   OC.push("C");
        // } else {
        //   OC.push("O");
        // }
        //LM for LM,AW-AW,CP-CP,QT-MP,NS-NPS,RV-OVERLAP,ZU-SUP,WI-MUSIC
        
        if (match.includes("SEGMENT")) {
          if (match.includes("/SEGMENT")) {
            OC.push("S");
          } else if (match.includes("SEGMENT")) {
            OC.push("T");
          }
        }
        if (match.includes("UNKNOWN_SEGMENT")) {
          if (match.includes("/UNKNOWN_SEGMENT")) {
            OC.push("U");
          } else if (match.includes("UNKNOWN_SEGMENT")) {
            OC.push("N");
          }
        }
        if (match.includes("dog barking")) {
          if (match.includes("/dog barking")) {
            OC.push("D");
          } else if (match.includes("dog barking")) {
            OC.push("G");
          }
        }
        if (match.includes("baby talking")) {
          if (match.includes("/baby talking")) {
            OC.push("B");
          } else if (match.includes("baby talking")) {
            OC.push("Y");
          }
        }
        if (match.includes("baby crying")) {
          if (match.includes("/baby crying")) {
            OC.push("C");
          } else if (match.includes("baby crying")) {
            OC.push("I");
          }
        }
        if (match.includes("animal sound")) {
          if (match.includes("/animal sound")) {
            OC.push("L");
          } else if (match.includes("animal sound")) {
            OC.push("O");
          }
        }
        if (match.includes("vehicle noise")) {
          if (match.includes("/vehicle noise")) {
            OC.push("V");
          } else if (match.includes("vehicle noise")) {
            OC.push("E");
          }
        }
        if (match.includes("bird sound")) {
          if (match.includes("/bird sound")) {
            OC.push("R");
          } else if (match.includes("bird sound")) {
            OC.push("M");
          }
        }
        if (match.includes("people talking")) {
          if (match.includes("/people talking")) {
            OC.push("K");
          } else if (match.includes("people talking")) {
            OC.push("P");
          }
        }
        if (match.includes("static noise")) {
          if (match.includes("/static noise")) {
            OC.push("Z");
          } else if (match.includes("static noise")) {
            OC.push("A");
          }
        }
        if (match.includes("<noise>")||match.includes("</noise>")) {
          if (match.includes("</noise>")) {
            OC.push("F");
          } else if (match.includes("<noise>")) {
            OC.push("H");
          }
        }
        if (match.includes("<talking>")||match.includes("</talking>")) {
          if (match.includes("</talking>")) {
            OC.push("J");
          } else if (match.includes("<talking>")) {
            OC.push("Q");
          }
        }
        if (match.includes("coughing")) {
          if (match.includes("/coughing")) {
            OC.push("W");
          } else if (match.includes("coughing")) {
            OC.push("X");
          }
        }
        if (match.includes("honking")) {
          if (match.includes("/honking")) {
            OC.push("0");
          } else if (match.includes("honking")) {
            OC.push("1");
          }
        }
        if (match.includes("<laughing>")||match.includes("</laughing>")) {
          if (match.includes("</laughing>")) {
            OC.push("2");
          } else if (match.includes("<laughing>")) {
            OC.push("3");
          }
        }
        if (match.includes("<crying>")||match.includes("</crying>")) {
          if (match.includes("</crying>")) {
            OC.push("4");
          } else if (match.includes("<crying>")) {
            OC.push("5");
          }
        }
        if (match.includes("music sound")) {
          if (match.includes("/music sound")) {
            OC.push("6");
          } else if (match.includes("music sound")) {
            OC.push("7");
          }
        }
        if (match.includes("phone ringing")) {
          if (match.includes("/phone ringing")) {
            OC.push("6");
          } else if (match.includes("phone ringing")) {
            OC.push("7");
          }
        }
        if (match.includes("insect noise")) {
          if (match.includes("</insect noise>")) {
            OC.push("8");
          } else if (match.includes("<insect noise>")) {
            OC.push("9");
          }
        }
        if (match.includes("bird squawk")) {
          if (match.includes("</bird squawk>")) {
            OC.push("F");
          } else if (match.includes("<bird squawk>")) {
            OC.push("H");
          }
        }
        if (match.includes("fan whirring")) {
          if (match.includes("</fan whirring>")) {
            OC.push("J");
          } else if (match.includes("<fan whirring>")) {
            OC.push("Q");
          }
        }
        if (match.includes("engine running")) {
          if (match.includes("</engine running>")) {
            OC.push("W");
          } else if (match.includes("<engine running>")) {
            OC.push("X");
          }
        }
        if (match.includes("water flowing")) {
          if (match.includes("</water flowing>")) {
            OC.push("0");
          } else if (match.includes("<water flowing>")) {
            OC.push("1");
          }
        }
        if (match.includes("child laughing")) {
          if (match.includes("</child laughing>")) {
            OC.push("2");
          } else if (match.includes("<child laughing>")) {
            OC.push("3");
          }
        }
        if (match.includes("child talking")) {
          if (match.includes("</child talking>")) {
            OC.push("4");
          } else if (match.includes("<child talking>")) {
            OC.push("5");
          }
        }
        if (match.includes("child crying")) {
          if (match.includes("</child crying>")) {
            OC.push("6");
          } else if (match.includes("<child crying>")) {
            OC.push("7");
          }
        }
        if (match.includes("train sound")) {
          if (match.includes("</train sound>")) {
            OC.push("8");
          } else if (match.includes("<train sound>")) {
            OC.push("9");
          }
        }
        if (match.includes("loudspeaker noise")) {
          if (match.includes("</loudspeaker noise>")) {
            OC.push("F");
          } else if (match.includes("<loudspeaker noise>")) {
            OC.push("H");
          }
        }
        if (match.includes("whispering")) {
          if (match.includes("</whispering>")) {
            OC.push("J");
          } else if (match.includes("<whispering>")) {
            OC.push("Q");
          }
        }
        if (match.includes("typing")) {
          if (match.includes("</typing>")) {
            OC.push("W");
          } else if (match.includes("<typing>")) {
            OC.push("X");
          }
        }
        if (match.includes("singing")) {
          if (match.includes("</singing>")) {
            OC.push("0");
          } else if (match.includes("<singing>")) {
            OC.push("1");
          }
        }

      });
      // FH,JQ,
      // WX,01,23,45,67,89,FH,JQ,WX,01,23,45,67,89,FH,JQ,WX,01,
      //console.log("OC..",OC);
      if(OC)
      {
        for(let i=0; i<OC.length; i++)
        {
          //console.log("OC,OC length",OC,OC.length);
          //console.log("i..",i);
          //ST,UN,DG,BY,CI,LO,VE,RM,KP,ZA,
          //LM,AW,CP,QT,NS,RV,ZU,YI
          if((OC[i]=="S" && OC[i+1]=="T")||(OC[i]=="U" && OC[i+1]=="N")||(OC[i]=="D" && OC[i+1]=="G")||(OC[i]=="B" && OC[i+1]=="Y")||(OC[i]=="C" && OC[i+1]=="I")||(OC[i]=="L" && OC[i+1]=="O")||(OC[i]=="V" && OC[i+1]=="E")||(OC[i]=="R" && OC[i+1]=="M")||(OC[i]=="K" && OC[i+1]=="P")||(OC[i]=="Z" && OC[i+1]=="A")
          ||(OC[i]=="F" && OC[i+1]=="H")||(OC[i]=="J" && OC[i+1]=="Q")||(OC[i]=="W" && OC[i+1]=="X")||(OC[i]=="0" && OC[i+1]=="1")||(OC[i]=="2" && OC[i+1]=="3")||(OC[i]=="4" && OC[i+1]=="5")||(OC[i]=="6" && OC[i+1]=="7")||(OC[i]=="8" && OC[i+1]=="9")

          )
          {
            OC.splice(i,2);
            i=-1;
            continue;
            //OC.splice(i+1,1);
          }
          //else if(OC[i]=="O" && OC[i+1]=="C")
          //console.log("OC after splice..",OC);
        }
        //console.log("final OC..",OC);
        if(OC && OC.length > 0)
        {
          let InvalidAngTag = "Invalid Angular tags found";
          segmentdata.push({ InvalidAngularTag: InvalidAngTag });
        }
        
      }
    }else{
      matches.map((match,index) => {
        console.log("match,index,OC",match,index,OC);
        debugger;
        // if (match.includes("/")) {
        //   OC.push("C");
        // } else {
        //   OC.push("O");
        // }
        //LM for LM,AW-AW,CP-CP,QT-MP,NS-NPS,RV-OVERLAP,ZU-SUP,WI-MUSIC
        
        if (match.includes("SEGMENT")) {
          if (match.includes("/SEGMENT")) {
            OC.push("T");
          } else if (match.includes("SEGMENT")) {
            OC.push("S");
          }
        }
        if (match.includes("UNKNOWN_SEGMENT")) {
          if (match.includes("/UNKNOWN_SEGMENT")) {
            OC.push("N");
          } else if (match.includes("UNKNOWN_SEGMENT")) {
            OC.push("U");
          }
        }
        if (match.includes("dog barking")) {
          if (match.includes("/dog barking")) {
            OC.push("G");
          } else if (match.includes("dog barking")) {
            OC.push("D");
          }
        }
        if (match.includes("baby talking")) {
          if (match.includes("/baby talking")) {
            OC.push("Y");
          } else if (match.includes("baby talking")) {
            OC.push("B");
          }
        }
        if (match.includes("baby crying")) {
          if (match.includes("/baby crying")) {
            OC.push("I");
          } else if (match.includes("baby crying")) {
            OC.push("C");
          }
        }
        if (match.includes("animal sound")) {
          if (match.includes("/animal sound")) {
            OC.push("O");
          } else if (match.includes("animal sound")) {
            OC.push("L");
          }
        }
        if (match.includes("vehicle noise")) {
          if (match.includes("/vehicle noise")) {
            OC.push("E");
          } else if (match.includes("vehicle noise")) {
            OC.push("V");
          }
        }
        if (match.includes("bird sound")) {
          if (match.includes("/bird sound")) {
            OC.push("M");
          } else if (match.includes("bird sound")) {
            OC.push("R");
          }
        }
        if (match.includes("people talking")) {
          if (match.includes("/people talking")) {
            OC.push("P");
          } else if (match.includes("people talking")) {
            OC.push("K");
          }
        }
        if (match.includes("static noise")) {
          debugger;
          if (match.includes("/static noise")) {
            OC.push("A");
          } else if (match.includes("static noise")) {
            OC.push("Z");
          }
        }
        if (match.includes("<noise>")||match.includes("</noise>")) {
          debugger;
          if (match.includes("</noise>")) {
            OC.push("F");
          } else if (match.includes("<noise>")) {
            OC.push("H");
          }
          console.log("OC inside if",OC);
        }
        if (match.includes("<talking>")||match.includes("</talking>")) {
          if (match.includes("</talking>")) {
            OC.push("J");
          } else if (match.includes("<talking>")) {
            OC.push("Q");
          }
        }
        if (match.includes("coughing")) {
          if (match.includes("/coughing")) {
            OC.push("W");
          } else if (match.includes("coughing")) {
            OC.push("X");
          }
        }
        if (match.includes("honking")) {
          debugger;
          if (match.includes("/honking")) {
            OC.push("0");
          } else if (match.includes("honking")) {
            OC.push("1");
          }
        }
        if (match.includes("<laughing>")||match.includes("</laughing>")) {
          if (match.includes("</laughing>")) {
            OC.push("2");
          } else if (match.includes("<laughing>")) {
            OC.push("3");
          }
        }
        if (match.includes("<crying>")||match.includes("</crying>")) {
          if (match.includes("</crying>")) {
            OC.push("4");
          } else if (match.includes("<crying>")) {
            OC.push("5");
          }
        }
        if (match.includes("music sound")) {
          if (match.includes("/music sound")) {
            OC.push("6");
          } else if (match.includes("music sound")) {
            OC.push("7");
          }
        }
        if (match.includes("phone ringing")) {
          if (match.includes("/phone ringing")) {
            OC.push("6");
          } else if (match.includes("phone ringing")) {
            OC.push("7");
          }
        }
        if (match.includes("insect noise")) {
          if (match.includes("</insect noise>")) {
            OC.push("8");
          } else if (match.includes("<insect noise>")) {
            OC.push("9");
          }
        }
        if (match.includes("bird squawk")) {
          if (match.includes("</bird squawk>")) {
            OC.push("F");
          } else if (match.includes("<bird squawk>")) {
            OC.push("H");
          }
        }
        if (match.includes("fan whirring")) {
          if (match.includes("</fan whirring>")) {
            OC.push("J");
          } else if (match.includes("<fan whirring>")) {
            OC.push("Q");
          }
        }
        if (match.includes("engine running")) {
          if (match.includes("</engine running>")) {
            OC.push("W");
          } else if (match.includes("<engine running>")) {
            OC.push("X");
          }
        }
        if (match.includes("water flowing")) {
          if (match.includes("</water flowing>")) {
            OC.push("2");
          } else if (match.includes("<water flowing>")) {
            OC.push("3");
          }
        }
        if (match.includes("child talking")) {
          if (match.includes("</child talking>")) {
            OC.push("4");
          } else if (match.includes("<child talking>")) {
            OC.push("5");
          }
        }
        if (match.includes("child crying")) {
          if (match.includes("</child crying>")) {
            OC.push("6");
          } else if (match.includes("<child crying>")) {
            OC.push("7");
          }
        }
        if (match.includes("train sound")) {
          if (match.includes("</train sound>")) {
            OC.push("8");
          } else if (match.includes("<train sound>")) {
            OC.push("9");
          }
        }
        if (match.includes("loudspeaker noise")) {
          if (match.includes("</loudspeaker noise>")) {
            OC.push("F");
          } else if (match.includes("<loudspeaker noise>")) {
            OC.push("H");
          }
        }
        if (match.includes("whispering")) {
          if (match.includes("</whispering>")) {
            OC.push("J");
          } else if (match.includes("<whispering>")) {
            OC.push("Q");
          }
        }
        if (match.includes("typing")) {
          if (match.includes("</typing>")) {
            OC.push("W");
          } else if (match.includes("<typing>")) {
            OC.push("X");
          }
        }
        if (match.includes("singing")) {
          if (match.includes("</singing>")) {
            OC.push("0");
          } else if (match.includes("<singing>")) {
            OC.push("1");
          }
        }
      });
      //console.log("OC..",OC);
      if(OC)
      {
        debugger;
        for(let i=0; i<OC.length; i++)
        {
          console.log("OC,OC length",OC,OC.length);
          //console.log("i..",i);
          //LM,AW,CP,QT,NS,RV,ZU,YI
          if((OC[i]=="S" && OC[i+1]=="T")||(OC[i]=="U" && OC[i+1]=="N")||(OC[i]=="D" && OC[i+1]=="G")||(OC[i]=="B" && OC[i+1]=="Y")||(OC[i]=="C" && OC[i+1]=="I")||(OC[i]=="L" && OC[i+1]=="O")||(OC[i]=="V" && OC[i+1]=="E")||(OC[i]=="R" && OC[i+1]=="M")||(OC[i]=="K" && OC[i+1]=="P")||(OC[i]=="Z" && OC[i+1]=="A")
          ||(OC[i]=="H" && OC[i+1]=="F")||(OC[i]=="Q" && OC[i+1]=="J")||(OC[i]=="X" && OC[i+1]=="W")||(OC[i]=="1" && OC[i+1]=="0")||(OC[i]=="3" && OC[i+1]=="2")||(OC[i]=="5" && OC[i+1]=="4")||(OC[i]=="7" && OC[i+1]=="6")||(OC[i]=="9" && OC[i+1]=="8")
          )
          {
            OC.splice(i,2);
            i=-1;
            continue;
            //OC.splice(i+1,1);
          }
          //else if(OC[i]=="O" && OC[i+1]=="C")
          //console.log("OC after splice..",OC);
        }
        console.log("final OC..",OC);
        
        if(OC && OC.length > 0)
        {
          let InvalidAngTag = "Invalid Angular tags found";
          segmentdata.push({ InvalidAngularTag: InvalidAngTag });
        }
        
      }
    }

    
    
  }
  
  //debugger;
  //angular bracket mismatch
  let angularbracketregex = new RegExp(
    /([\<]+\S.*?[\>]+)|\S[\>]+|[\<]+|[\>]|[\<]|([\<]+\S.*?[\/>]+)/
  );
  /// |[\<]+\S.*?[\/>]+
  let Angulartag = angularbracketregex.test(value);
  let angTag = ["<SEGMENT>", "</SEGMENT>", "<UNKNOWN_SEGMENT>", "</UNKNOWN_SEGMENT>", "<SIL>", "<PAUSE>", "<dog barking>", "</dog barking>", "<baby talking>", "</baby talking>", "<baby crying>", "</baby crying>","<animal sound>", "</animal sound>", "<vehicle noise>", "</vehicle noise>", "<bird sound>", "</bird sound>", "<people talking>", "</people talking>", "<static noise>", "</static noise>",
  "<noise>","</noise>","<talking>","</talking>","<coughing>","</coughing>","<honking>","</honking>","<laughing>","</laughing>","<crying>","</crying>","<music sound>","</music sound>","<phone ringing>","</phone ringing>","<insect noise>","</insect noise>","<bird squawk>","</bird squawk>","<fan whirring>","</fan whirring>","<engine running>","</engine running>","<water flowing>","</water flowing>",
  "<child laughing>","</child laughing>","<child talking>","</child talking>","<child crying>","</child crying>","<train sound>","</train sound>","<loudspeaker noise>","</loudspeaker noise>","<whispering>","</whispering>","<typing>","</typing>","<singing>","</singing>"];
  //debugger;
  if (Angulartag) {
    let cntOpen = value.split("<").length - 1;
    let cntClose = value.split(">").length - 1;
    if (cntOpen != cntClose) {
      let AngularMismatch = "Incorrect Angular bracket found";
      segmentdata.push({ AngularMismatch: AngularMismatch });
    } else {
      // AngularMismatch = "";
      // segmentdata.push({ AngularMismatch: AngularMismatch });
      let angflag = false;
      let angtagmatches = value.match(
        /([\<]+\S.*?[\>]+)|([\<\/]+\S.*?[\>]+)|\S[\>]+|[\<]+|[\>]|[\<]|([\<]+\S.*?[\/\>]+)/g
      );
      //console.log(angtagmatches);

      let InvalidAngularTag = "";
        //debugger;
      for (let i = 0; i < angtagmatches.length; i++) {
        if (angtagmatches[0] == "<") {
          angflag = true;
          break;
        }
        for (let j = 0; j < angTag.length; j++) {
          if (angtagmatches[i] === angTag[j]) {
            angflag = true;
            break;
          } else {
            angflag = false;
          }
        }
        if (!angflag)
          InvalidAngularTag = InvalidAngularTag + " " + "Invalid Angular tags";
      }

      if (InvalidAngularTag.match("Invalid Angular tags")) {
        InvalidAngularTag = "Invalid Angular tags found";
        segmentdata.push({ InvalidAngularTag: InvalidAngularTag });
      }
    }
    //letangularbracketregex2;
  }


   //{} bracket mismatch
  let curlybracketregex = new RegExp(
    /([\{]+\S.*?[\}]+)|\S[\}]+|[\{]+|[\}]|[\{]|([\{]+\S.*?[\/}]+)/
  );
  /// |[\<]+\S.*?[\/>]+
  let curlytag = curlybracketregex.test(value);
  if (curlytag) {
    let cntOpen = value.split("{").length - 1;
    let cntClose = value.split("}").length - 1;
    if (cntOpen != cntClose) {
      let curlyMismatch = "Incorrect Curly bracket found";
      segmentdata.push({ CurlyMismatch: curlyMismatch });
    } else {
      // AngularMismatch = "";
      // segmentdata.push({ AngularMismatch: AngularMismatch });
      let curlyflag = false;
      let curlytagmatches = value.match(
        /([\{][\}]+)|\S[\}]+|[\{]+|[\}]|[\{]|([\{][\/\}]+)/g
      );
      //console.log(angtagmatches);

      let InvalidCurlyTag = "";
        //debugger;
      for (let i = 0; i < curlytagmatches.length; i++) {
        if (curlytagmatches[0] == "{") {
          curlyflag = true;
          break;
        }
        for (let j = 0; j < curlytag.length; j++) {
          if (curlytagmatches[i] === curlytag[j]) {
            curlyflag = true;
            break;
          } else {
            curlyflag = false;
          }
        }
        if (!curlyflag)
          InvalidCurlyTag = InvalidCurlyTag + " " + "Invalid Curly tags";
      }

      if (InvalidCurlyTag.match("Invalid Curly tags")) {
        InvalidCurlyTag = "Invalid Curly bracket found";
        segmentdata.push({ InvalidCurlyBracket: InvalidCurlyTag });
      }
    }
    //letangularbracketregex2;
  }


  //debugger;
  if (
    value.includes(" SIL") |
    value.includes("S I L") |
    value.includes("SI L") |
    value.includes("S IL")
  ) {
    //if (!value.includes("<SIL/>")) {
    let InvalidSILTag = "Invalid SIL tag(s) found";
    segmentdata.push({ InvalidSILTag: InvalidSILTag });
    //}
  }

  if( value.includes("<SIL>") ){
    let silReg = /^[<SIL>]/
    let temp = value.replace("<SIL>", "")
    // let silCheck = silReg.test(value);
    // console.log("silCheck", silCheck)
    if(temp != "" && temp != " "){
      let InvalidSILTag = "Only <SIL> tag & single <SIL> is allowed";
      segmentdata.push({ InvalidSILTag: InvalidSILTag }); 
    }
  }

  //debugger;
  // let firstlettercapregex = /(?<=(?:^|[.?!])\W*)[a-z]/g;
  // let temp = value.replaceAll("<SEGMENT>","").replaceAll("<UNKNOWN_SEGMENT>","").replaceAll("<PAUSE>","").replaceAll("<dog barking>","").replaceAll("</dog barking>","").replaceAll("<baby talking>","").replaceAll("</baby talking>","").replaceAll("<baby crying>","").replaceAll("</baby crying>","").replaceAll("<animal sound>","").replaceAll("</animal sound>","").replaceAll("<vehicle noise>","").replaceAll("</vehicle noise>","").replaceAll("<bird sound>","").replaceAll("</bird sound>","").replaceAll("<people talking>","").replaceAll("</people talking>","").replaceAll("<static noise>","").replaceAll("</static noise>","").replaceAll("[inaudible]","").replaceAll("[unintelligible]", "").replaceAll("[lip smacks]", "").replaceAll("[breathing]", "").replaceAll("[child_crying]", "").replaceAll("[child_laughing]", "").replaceAll("[child_talking]", "").replaceAll("[click]", "").replaceAll("[barking]", "").replaceAll("[beep]", "").replaceAll("[cough]", "").replaceAll("[horn]", "").replaceAll("[laughter]", "").replaceAll("[motorcycle]", "").replaceAll("[music]", "").replaceAll("[noise]", "").replaceAll("[ringing]", "").replaceAll("[sneezing]", "").replaceAll("[talking]", "").replaceAll("[throat_clearing]", "").replaceAll("[baby_crying]", "").replaceAll("[baby_talking]", "");

  // let newlmtg = firstlettercapregex.test(temp);
  //   if(newlmtg)
  //   {
  //     let InvalidFirstLetter = "Please start each sentence with capital letter.";
  //     segmentdata.push({ InvalidFirstLetter: InvalidFirstLetter });
  //   }


//validation for Empty tags
let lmTag = /<SEGMENT>.*?<\/SEGMENT>|<UNKNOWN_SEGMENT>.*?<\/UNKNOWN_SEGMENT>|<dog barking>.*?<\/dog barking>|<baby talking>.*?<\/baby talking>|<baby crying>.*?<\/baby crying>|<animal sound>.*?<\/animal sound>|<vehicle noise>.*?<\/vehicle noise>|<bird sound>.*?<\/bird sound>|<people talking>.*?<\/people talking>|<static noise>.*?<\/static noise>|<noise>.*?<\/noise>|<talking>.*?<\/talking>|<coughing>.*?<\/coughing>|<honking>.*?<\/honking>|<laughing>.*?<\/laughing>|<crying>.*?<\/crying>|<music sound>.*?<\/music sound>|<phone ringing>.*?<\/phone ringing>|<insect noise>.*?<\/insect noise>|<bird squawk>.*?<\/bird squawk>|<fan whirring>.*?<\/fan whirring>|<engine running>.*?<\/engine running>|<water flowing>.*?<\/water flowing>|<child laughing>.*?<\/child laughing>|<child talking>.*?<\/child talking>|<child crying>.*?<\/child crying>|<train sound>.*?<\/train sound>|<loudspeaker noise>.*?<\/loudspeaker noise>|<whispering>.*?<\/whispering>|<typing>.*?<\/typing>|<singing>.*?<\/singing>/g ; 
 //console.log("lmTag", lmTag)
 //console.log("value", value)
let lmmatchVal=value.match(lmTag);
//console.log("lmmatchVal", lmmatchVal)
if(lmmatchVal!==null && lmmatchVal!==undefined && lmmatchVal!=="") 
{   
for(var i=0; i<lmmatchVal.length; i++)
{  let flag=0;  
    //console.log("i", i)   
   let newtext=lmmatchVal[i].replaceAll("<SEGMENT>","").replaceAll("</SEGMENT>","").replaceAll("<UNKNOWN_SEGMENT>","").replaceAll("</UNKNOWN_SEGMENT>","").replaceAll("<dog barking>","").replaceAll("</dog barking>","").replaceAll("<baby talking>","").replaceAll("</baby talking>","").replaceAll("<baby crying>","").replaceAll("</baby crying>","").replaceAll("<animal sound>","").replaceAll("</animal sound>","").replaceAll("<vehicle noise>","").replaceAll("</vehicle noise>","").replaceAll("<bird sound>","").replaceAll("</bird sound>","").replaceAll("<people talking>","").replaceAll("</people talking>","").replaceAll("<static noise>","").replaceAll("</static noise>","").replaceAll(" ","")
   .replaceAll("<noise>", "").replaceAll("</noise>", "").replaceAll("<talking>", "").replaceAll("</talking>", "").replaceAll("<coughing>", "").replaceAll("</coughing>", "").replaceAll("<honking>", "").replaceAll("</honking>", "")
  .replaceAll("<laughing>", "").replaceAll("</laughing>", "").replaceAll("<crying>", "").replaceAll("</crying>", "").replaceAll("<music sound>", "").replaceAll("</music sound>", "").replaceAll("<phone ringing>", "").replaceAll("</phone ringing>", "")
  .replaceAll("<insect noise>", "").replaceAll("</insect noise>", "").replaceAll("<bird squawk>", "").replaceAll("</bird squawk>", "").replaceAll("<fan whirring>", "").replaceAll("</fan whirring>", "").replaceAll("<engine running>", "").replaceAll("</engine running>", "")
  .replaceAll("<water flowing>", "").replaceAll("</water flowing>", "").replaceAll("<child laughing>", "").replaceAll("</child laughing>", "").replaceAll("<child talking>", "").replaceAll("</child talking>", "").replaceAll("<child crying>", "").replaceAll("</child crying>", "").replaceAll("<train sound>", "").replaceAll("</train sound>", "")
  .replaceAll("<loudspeaker noise>", "").replaceAll("</loudspeaker noise>", "").replaceAll("<whispering>", "").replaceAll("</whispering>", "").replaceAll("<typing>", "").replaceAll("</typing>", "").replaceAll("<singing>", "").replaceAll("</singing>", "");  
   //console.log("newtext", newtext)                
   if( newtext.length==0 && lmmatchVal[i] != "" || lmmatchVal===null || lmmatchVal===undefined ||lmmatchVal==="")  
   {
     flag=1;
   }
   //console.log("flag", flag)
   if(flag)
   {
     let InvalidBlankTag = "Blank tag found";
     segmentdata.push({ InvalidBlankTag: InvalidBlankTag });
     break;
 }
}
}
  
  // if (
  //   (spkid == "" || spkid == false || spkid == "defaultvalue" || spkid == "undefined")
  // ) {
  //   let speakerIdEmpty = "Speaker Id is not added for this segment";
  //   segmentdata.push({ speakerIdEmpty: speakerIdEmpty });
  // }


  //validation for English language text without braces{}
  let angularsquarebracket=value.replaceAll("<SIL>","").replaceAll("<SEGMENT>","").replaceAll("<UNKNOWN_SEGMENT>","").replaceAll("<PAUSE>","").replaceAll("<dog barking>","").replaceAll("</dog barking>","").replaceAll("<baby talking>","").replaceAll("</baby talking>","").replaceAll("<baby crying>","").replaceAll("</baby crying>","").replaceAll("<animal sound>","").replaceAll("</animal sound>","").replaceAll("<vehicle noise>","").replaceAll("</vehicle noise>","").replaceAll("<bird sound>","").replaceAll("</bird sound>","").replaceAll("<people talking>","").replaceAll("</people talking>","").replaceAll("<static noise>","").replaceAll("</static noise>","").replaceAll("[inaudible]","").replaceAll("[unintelligible]", "").replaceAll("[lip smacks]", "").replaceAll("[breathing]", "").replaceAll("[child_crying]", "").replaceAll("[child_laughing]", "").replaceAll("[child_talking]", "").replaceAll("[click]", "").replaceAll("[barking]", "").replaceAll("[beep]", "").replaceAll("[coughing]", "").replaceAll("[horn]", "").replaceAll("[laughter]", "").replaceAll("[motorcycle]", "").replaceAll("[music]", "").replaceAll("[noise]", "").replaceAll("[ringing]", "").replaceAll("[sneezing]", "").replaceAll("[talking]", "").replaceAll("[throat_clearing]", "").replaceAll("[baby_crying]", "").replaceAll("[baby_talking]", "")
  .replaceAll("[inhale]", "").replaceAll("[exhale]", "").replaceAll("[alarms]", "").replaceAll("[fan_whirring]", "").replaceAll("[engine_running]", "").replaceAll("[water_flowing]", "").replaceAll("[yawning]", "")
  .replaceAll("[tongue_clicks]", "").replaceAll("[door_closing]", "").replaceAll("[typing]", "").replaceAll("[clapping]", "").replaceAll("[whispering]", "").replaceAll("[bird_sound]", "")
  .replaceAll("<noise>", "").replaceAll("</noise>", "").replaceAll("<talking>", "").replaceAll("</talking>", "")
  .replaceAll("<noise>", "").replaceAll("</noise>", "").replaceAll("<talking>", "").replaceAll("</talking>", "").replaceAll("<coughing>", "").replaceAll("</coughing>", "").replaceAll("<honking>", "").replaceAll("</honking>", "")
  .replaceAll("<laughing>", "").replaceAll("</laughing>", "").replaceAll("<crying>", "").replaceAll("</crying>", "").replaceAll("<music sound>", "").replaceAll("</music sound>", "").replaceAll("<phone ringing>", "").replaceAll("</phone ringing>", "")
  .replaceAll("<insect noise>", "").replaceAll("</insect noise>", "").replaceAll("<bird squawk>", "").replaceAll("</bird squawk>", "").replaceAll("<fan whirring>", "").replaceAll("</fan whirring>", "").replaceAll("<engine running>", "").replaceAll("</engine running>", "")
  .replaceAll("<water flowing>", "").replaceAll("</water flowing>", "").replaceAll("<child laughing>", "").replaceAll("</child laughing>", "").replaceAll("<child talking>", "").replaceAll("</child talking>", "").replaceAll("<child crying>", "").replaceAll("</child crying>", "").replaceAll("<train sound>", "").replaceAll("</train sound>", "")
  .replaceAll("<loudspeaker noise>", "").replaceAll("</loudspeaker noise>", "").replaceAll("<whispering>", "").replaceAll("</whispering>", "").replaceAll("<typing>", "").replaceAll("</typing>", "").replaceAll("<singing>", "").replaceAll("</singing>", "");


const enregex = /\b[a-zA-Z\s]+\b(?![^{]*\})/g;
let enmatchVal=angularsquarebracket.match(enregex);
//console.log("enmatchVal", enmatchVal)

if(enmatchVal  && !lan == "English")
{
  let InvalidEnglishWords = "Other language text without braces({}) found";
  segmentdata.push({ InvalidEnglishWords: InvalidEnglishWords });
}


//Validation for to detect non english characters in curly brackets
//debugger;
// let curlyregex = /{([^}]*)}/g
// let curlymatchVal=angularsquarebracket.match(curlyregex);
// //console.log("curlymatchVal", curlymatchVal)

// if(curlymatchVal && curlymatchVal.length > 0){
//   for(i=0; i<curlymatchVal.length; i++){
//     let engregex = /^[a-zA-Z_\s]+$/;
//     let txt=curlymatchVal[i].replaceAll("{","");
//     let txt1=txt.replaceAll("}","");
//     let engmatchVal=txt1.match(engregex);
//     //console.log("engmatchVal", engmatchVal)
//     if(!engmatchVal){
//       let InvalidCurlyBrackets = "Non english text found with curly brackets";
//       segmentdata.push({ InvalidCurlyBrackets: InvalidCurlyBrackets });
//       break;
//     }
   
//   }
// }

//validation for Multiple fullstop
  //let hindiFSMatchVal = value.match(/[\|\.]/g);
  let hindiFSMatchVal = value.match(/[\.\u0964]/g);
    //console.log("hindiFSMatchVal", value, hindiFSMatchVal)
  if(hindiFSMatchVal){
    let hindiFullStopcount = hindiFSMatchVal.length;
    //console.log("hindiFullStopcount", hindiFullStopcount)
    if(hindiFullStopcount > 1){
      let hindiFullStop = "Multiple fullstop found. Please remove extra fullstop within a sentence only.";
      segmentdata.push({ MultipleFullStop: hindiFullStop });
    }
  }

  //Validation for if fullstop, --, । not there in the sentence.
    if(lan == "Urdu"){
      const endWithFullStopRegex = /[\u06D4\.]$/;
      let urdusentence = endWithFullStopRegex.test(value);
        if(urdusentence){
          let invalidLastChar = "Sentence should end with period .";
          segmentdata.push({ invalidLastChar: invalidLastChar });
        }
    }else{
      // switch(value.charAt(value.length-1)) {
      //   case ".":
      //     break;
      //   case "--":
      //     // code block
      //     break;
      //   case "।":
      //     // code block
      //     break;
      //   default:
      //     let invalidLastChar = "Sentence should end with . or -- or ।";
      //     segmentdata.push({ invalidLastChar: invalidLastChar });
      // }
      switch(true) {
        case value.indexOf(".") !== -1:
          //console.log("full stop found!");
          break;
        case value.indexOf("--") !== -1:
          //console.log("Two hyphens found!");
          // code block
          break;
        case value.indexOf("।") !== -1:
          //console.log("hindi full stop found!");
          // code block
          break;
        default:
          let invalidLastChar = "Sentence should end with . or -- or ।";
          segmentdata.push({ invalidLastChar: invalidLastChar });
      }
    }
  

  // //word count
  // let spaces = value.match(/\s+/g);
  // let words = spaces ? spaces.length : 0;
  // if(words < 6){
  //   //console.log("words", words)
  //   let wordCount = "Word count is below 6. please check with admin"
  //   segmentdata.push({ wordCount: wordCount });
  // }






  errors.segmentdata = segmentdata;
  return errors;
}
