import React, { useState, useEffect, useMemo, useRef } from "react";
import Logo from "../assets/images/texlang-Logo.svg";
import {
  LogOut,
  DoneStatus,
  ErrorStatus,
  AddSpeakerBtn,
  PopupClose,
  SignOut,
  SpeakerRemove,
  ErrorIcon,
} from "./icons.component.jsx";
import axios from "axios";
import reqHeader from "../helper/reqHeader.js";
import { format } from "date-fns";
import Peaks from "peaks.js";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import validate from "./Validations/ggrtph2validation";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import GuidelinesContent from "./Popups/megdapguidelines.jsx";
import KeyShortcutContents from "./Popups/keyboardshortcuts.jsx";
import AddSpeakerContent from "./Popups/addspeaker.jsx";
import GGRTPH2ProofReadSubmit from "./Popups/ggrtph2-proofread-submit.jsx";
//import FindAndReplace from "./Popups/findAndReplace"
import { useSelector } from "react-redux";
import { useBeforeunload } from "react-beforeunload";
import IdleTimer from "react-idle-timer";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserProfile from "./userProfile.jsx";
import Spinner from "./spinner.jsx"
import { useDispatch } from "react-redux";
import { logout } from "../actions/userActions.js";
//import Konva from "konva"
//import RightClickMenu from '../helper/RightClickMenu.js'
//import "@webscopeio/react-textarea-autocomplete/style.css";

Modal.setAppElement("#root");

const GGRTEditorPhase2 = (props) => {
  //React Hooks
  const dispatch = useDispatch();
  const [datalist, setDataList] = useState([]);
  const [readJson, setReadJson] = useState([]);
  const [autoTranscription, setAutoTranscription] = useState("");
  const [urlPath, setUrlPath] = useState();
  const [arrayAudioBuffer, setArrayAudioBuffer] = useState();
  const [addedSegments, setAddedSegments] = useState([]);
  const [allPeaks, setAllPeaks] = useState(null);
  //const [selectSegment, setSelectSegment] = useState([]);
  const [mergeSegment, setMergeSegment] = useState([]);
  const [pane, setPane] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  const [formErrors, setFormErrors] = useState([]);
  const [segmentError, setSegmentError] = useState([]);
  const [isplay, setIsPlay] = useState(false);
  const [flag, setFlag] = useState();
  //const [fileopenstatus, setFileopenstatus] = useState(false);
  const [accessDetails, setAccessDetails] = useState({
    accessedIP: "",
    accessedBy: "",
    accessedOn: "",
  });
  const [rowAdded, setRowAdded] = useState([]);


  let angularTags = [];

  datalist && datalist.srcLang == "Urdu" ? (  angularTags = [
    {name: "SIL",string: "<SIL>"},
    {name: "PAUSE",string: "<PAUSE>"},
    {name: "UNKNOWN_SEGMENT",string: "<UNKNOWN_SEGMENT>"},
    {name: "dog barking",string: "</dog barking><dog barking>"},
    {name: "baby talking",string: "</baby talking><baby talking>"},
    {name: "baby crying",string: "</baby crying><baby crying>"},
    {name: "animal sound",string: "</animal sound><animal sound>"},
    {name: "vehicle noise",string: "</vehicle noise><vehicle noise>"},
    {name: "bird sound",string: "</bird sound><bird sound>"},
    {name: "people talking",string: "</people talking><people talking>"},
    {name: "static noise",string: "</static noise><static noise>"},
    {name: "noise",string: "</noise><noise>"},
    {name: "talking",string: "</talking><talking>"},
    {name: "coughing",string: "</coughing><coughing>"},
    {name: "honking",string: "</honking><honking>"},
    {name: "laughing",string: "</laughing><laughing>"},
    {name: "crying",string: "</crying><crying>"},
    {name: "music sound",string: "</music sound><music sound>"},
    {name: "phone ringing",string: "</phone ringing><phone ringing>"},
    {name: "insect noise",string: "</insect noise><insect noise>"},
    {name: "bird squawk",string: "</bird squawk><bird squawk>"},
    {name: "fan whirring",string: "</fan whirring><fan whirring>"},
    {name: "engine running",string: "</engine running><engine running>"},
    {name: "water flowing",string: "</water flowing><water flowing>"},
    {name: "child laughing",string: "</child laughing><child laughing>"},
    {name: "child talking",string: "</child talking><child talking>"},
    {name: "child crying",string: "</child crying><child crying>"}, 
    {name: "train sound",string: "</train sound><train sound>"},
    {name: "loudspeaker noise",string: "</loudspeaker noise><loudspeaker noise>"},
    {name: "whispering",string: "</whispering><whispering>"},
    {name: "typing",string: "</typing><typing>"},
    {name: "singing",string: "</singing><singing>"},
  ]):(angularTags = [
    {name: "SIL",string: "<SIL>"},
    {name: "PAUSE",string: "<PAUSE>"},
    {name: "UNKNOWN_SEGMENT",string: "<UNKNOWN_SEGMENT>"},
    {name: "dog barking",string: "<dog barking></dog barking>"},
    {name: "baby talking",string: "<baby talking></baby talking>"},
    {name: "baby crying",string: "<baby crying></baby crying>"},
    {name: "animal sound",string: "<animal sound></animal sound>"},
    {name: "vehicle noise",string: "<vehicle noise></vehicle noise>"},
    {name: "bird sound",string: "<bird sound></bird sound>"},
    {name: "people talking",string: "<people talking></people talking>"},
    {name: "static noise",string: "<static noise></static noise>"},
    {name: "noise",string: "<noise></noise>"},
    {name: "talking",string: "<talking></talking>"},
    {name: "coughing",string: "<coughing></coughing>"},
    {name: "honking",string: "<honking></honking>"},
    {name: "laughing",string: "<laughing></laughing>"},
    {name: "crying",string: "<crying></crying>"},
    {name: "music sound",string: "<music sound></music sound>"},
    {name: "phone ringing",string: "<phone ringing></phone ringing>"},
    {name: "insect noise",string: "<insect noise></insect noise>"},
    {name: "bird squawk",string: "<bird squawk></bird squawk>"},
    {name: "fan whirring",string: "<fan whirring></fan whirring>"},
    {name: "engine running",string: "<engine running></engine running>"},
    {name: "water flowing",string: "<water flowing></water flowing>"},
    {name: "child laughing",string: "<child laughing></child laughing>"},
    {name: "child talking",string: "<child talking></child talking>"},
    {name: "child crying",string: "<child crying></child crying>"}, 
    {name: "train sound",string: "<train sound></train sound>"},
    {name: "loudspeaker noise",string: "<loudspeaker noise></loudspeaker noise>"},
    {name: "whispering",string: "<whispering></whispering>"},
    {name: "typing",string: "<typing></typing>"},
    {name: "singing",string: "<singing></singing>"},
    ])

  
  
  const slashTags = [
    { name: "unintelligible", string: "[unintelligible]" },
    { name: "inaudible",string: "[inaudible]"},
    { name: "lip smacks",string: "[lip smacks]"},
    { name: "breathing",string: "[breathing]"},
    { name: "child_crying",string: "[child_crying]"},
    { name: "child_laughing",string: "[child_laughing]"},
    { name: "child_talking",string: "[child_talking]"},
    { name: "click",string: "[click]"},
    { name: "barking",string: "[barking]"},
    { name: "beep",string: "[beep]"},
    { name: "coughing",string: "[coughing]"},
    { name: "horn",string: "[horn]"},
    { name: "laughter",string: "[laughter]"},
    { name: "motorcycle",string: "[motorcycle]"},
    { name: "music",string: "[music]"},
    { name: "noise",string: "[noise]"},
    { name: "ringing",string: "[ringing]"},
    { name: "sneezing",string: "[sneezing]"},
    { name: "talking",string: "[talking]"},
    { name: "throat_clearing",string: "[throat_clearing]"},
    { name: "baby_crying",string: "[baby_crying]"},
    { name: "baby_talking",string: "[baby_talking]"},
    { name: "inhale",string: "[inhale]"},
    { name: "exhale",string: "[exhale]"},
    { name: "alarms",string: "[alarms]"},
    { name: "fan_whirring",string: "[fan_whirring]"},
    { name: "engine_running",string: "[engine_running]"},
    { name: "water_flowing",string: "[water_flowing]"},
    { name: "yawning",string: "[yawning]"},
    { name: "tongue_clicks",string: "[tongue_clicks]"},
    { name: "door_closing",string: "[door_closing]"},
    { name: "typing",string: "[typing]"},
    { name: "clapping",string: "[clapping]"},
    { name: "whispering",string: "[whispering]"},
    { name: "bird_sound",string: "[bird_sound]"},
    ];

  //Props
  let id = props.match.params.id;
  //console.log("id", id)

  //Logged In Users Information
  const userLogin = useSelector((state) => state.userLogin);

  //console.log("userInfo is ", userLogin.user.userType);

  const { id: userID, userType } = userLogin.user;

  //console.log("userType", userType)

  const { ipaddr } = userLogin;
  
  
  window.addEventListener("storage", (event)=>{
      console.log("logout-event")
      if (event.key == 'logout-event') {
        dispatch(logout())
      }
  })
  

  const stickyWave = document.getElementById("stickywave");
  const segmentcontainer = document.getElementById("segmentwrap");
  const sticky = stickyWave && stickyWave.offsetTop;

  useEffect(()=>{
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        stickyWave && stickyWave.classList.add("sticky");
        segmentcontainer && segmentcontainer.classList.add("segmentcontainer-mar-top")
      } else {
        stickyWave && stickyWave.classList.remove("sticky");
        segmentcontainer && segmentcontainer.classList.remove("segmentcontainer-mar-top")
      }
      return () => {
        window.removeEventListener("scroll", scrollCallBack);
      };
    });
  },[sticky])


  //check isfileAccessed flag status and close window if 'true' else set flag as 'true' and continue transcription work
  // useEffect(() => {
  //   async function checkisFileAccessed() {
  //     let url = "";

  //     const headers = reqHeader(true, true, true);
  //     const params = {
  //       userID: userID,
  //       accessedIP: ipaddr,
  //       accessedOn: accessDetails.accessedOn,
  //     };
  //     const config = { headers, params };

  //     url = `${process.env.REACT_APP_URL}/transcription/editor/isfileaccessed/${id}`;

  //     await axios
  //       .get(url, config)
  //       .then((res) => {
  //         //console.log("res inside checkisFileAccessed is ", res);
  //         if (res.data.data) {
  //           //setFileopenstatus(true);
  //           //toast.error("File is already Opened.");
  //           alert("File is already open by another user.");
  //           window.open("", "_parent", "");
  //           window.close();
  //         } else {
  //           const body = {
  //             type: "onOpen",
  //             isfileAccessed: true,
  //             accessedBy: userID,
  //             accessedIP: ipaddr,
  //           };
  //           url = `${process.env.REACT_APP_URL}/transcription/editor/updatefileaccessedstatus/${id}`;
  //           const params = {
  //             accessedIP: accessDetails.accessedIP,
  //             accessedBy: accessDetails.accessedBy,
  //             accessedOn: accessDetails.accessedOn,
  //           };

  //           const config = { headers, params };

  //           axios
  //             .put(url, body, config)
  //             .then((res) => {
  //               //console.log("Updated file access status to true");

  //               const { data } = res.data;
  //               //console.log("Updated data is ", data);

  //               setAccessDetails({
  //                 accessedIP: data.accessedIP,
  //                 accessedBy: data.accessedBy,
  //                 accessedOn: data.accessedOn,
  //               });
  //             })
  //             .catch((err) => {
  //               console.log("err", err);
  //             });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //       });
  //   }
  //   checkisFileAccessed();
  // }, []);

  // useBeforeunload((event) => {
  //   if (
  //     accessDetails.accessedIP !== "" &&
  //     accessDetails.accessedBy !== "" &&
  //     accessDetails.accessedOn !== ""
  //   ) {
  //     window.onbeforeunload = null;

  //     event.preventDefault();

  //     const url = `${process.env.REACT_APP_URL}/transcription/editor/updatefileaccessedstatus/${id}`;
  //     const headers = reqHeader(true, true, true);
  //     const params = {
  //       accessedIP: accessDetails.accessedIP,
  //       accessedBy: accessDetails.accessedBy,
  //       accessedOn: accessDetails.accessedOn,
  //       accessDetails: accessDetails,
  //     };
  //     const config = { headers, params };
  //     const body = {
  //       type: "onClose",
  //       isfileAccessed: false,
  //       accessedIP: "",
  //       accessedBy: "",
  //     };
  //     axios
  //       .put(url, body, config)
  //       .then((res) => {
  //         console.log("Updated file access status to false");
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //       });
  //   }
  // });

  // const accessDetRef = useRef({
  //   accessedIP: "",
  //   accessedBy: "",
  //   accessedOn: "",
  // });

  // useEffect(() => {
  //   accessDetRef.current = accessDetails;
  // }, [accessDetails]);

  // reset isfileAccessed flag as false when window is getting closed
  // useEffect(() => {
  //   const onbeforeunloadFn = () => {
  //     //localStorage.setItem('color', 'red')

  //     //console.log("window is closing");
  //     const url = `${process.env.REACT_APP_URL}/transcription/editor/updatefileaccessedstatus/${id}`;
  //     const headers = reqHeader(true, true, true);
  //     const params = {
  //       accessedIP: accessDetRef.current.accessedIP,
  //       accessedBy: accessDetRef.current.accessedBy,
  //       accessedOn: accessDetRef.current.accessedOn,
  //       accessDetails: accessDetRef.current,
  //     };
  //     const config = { headers, params };
  //     const body = {
  //       type: "onClose",
  //       isfileAccessed: false,
  //       accessedIP: "",
  //       accessedBy: "",
  //     };
  //     axios
  //       .put(url, body, config)
  //       .then((res) => {
  //         //console.log("Updated file access status to false");
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //       });
  //   };

  //   window.addEventListener("beforeunload", onbeforeunloadFn);
  //   return () => {
  //     window.removeEventListener("beforeunload", onbeforeunloadFn);
  //   };
  // }, []);

  //FileDetails and ReadAudio Functions
  
  useEffect(()=>{
    console.log("Inside read audio")
    async function getFilleDetails() {
      let url = "";
      let spkGGID = [];
      let fileName = ""
      const headers = reqHeader(true, true, true);
      const config = { headers };

      url = `${process.env.REACT_APP_URL}/transcription/editor/readAudio/${id}`;

      await axios
        .get(url, config)
        .then((res) => {
          //console.log("datalist res", res.data.data)
          //log.info(res.data.data)
          setDataList(res.data.data);
          setReadJson(res.data.buffer);
          setAutoTranscription(res.data.transcription)
          fileName = res.data.data.fileName.split("_");

          spkGGID.push({speakerid:fileName[2] + "_" + fileName[3]})
          setRowAdded(spkGGID);
        })
        .catch((err) => {
          console.log("catch err", err)
          //log.error(err);
        });
    }
    getFilleDetails();
  },[])
  
  
  useEffect(() => {
    async function readAudioFile() {
      const AudioFileurl = `${process.env.REACT_APP_URL}/transcription/transcription/previewAudioFiles`;
      const headers = reqHeader(true, true, true);
      console.log("datalist", datalist)
      //var revisedUrl =
        //datalist.fileLocation &&
        //datalist.fileLocation.replace("/TexLang-Cloud-Storage/", "/");
        console.log("datalist", datalist.fileLocation)
        let revisedUrl = process.env.REACT_APP_ENVIRONMENT === "production"
        ? datalist.fileLocation && datalist.fileLocation.replace("/texlang-cloud-storage/", "/")
        : datalist.fileLocation && datalist.fileLocation.replace("/staging-texlang-cloudstorage/", "/");
        console.log("revisedUrl", revisedUrl);
        const params = {
          fpath: revisedUrl && revisedUrl,
          type: "Transcription",
        };

      const config = { headers, params };

      if (datalist.fileLocation && revisedUrl) {
        await axios
          .get(AudioFileurl, config)
          .then((response) => {
            console.log("catch response", response)
            const { url } = response.data.data;
            setUrlPath(url);
          })
          .catch((err) => {
            console.log("catch err", err)

            //err ? log.error(err) : console.log("catch err", err);
             //log.info("one");
          });
      }
    }
    readAudioFile();
  }, [datalist.fileLocation]);

  //Convert AudioContext into AudioBuffer
  useEffect(() => {
    async function audioArrayBuffer() {
      let audioCtx;
      if (window.webkitAudioContext) {
        audioCtx = new window.webkitAudioContext();
      } else {
        audioCtx = new window.AudioContext();
      }
      let source;
      source = audioCtx.createBufferSource();
      let request = new XMLHttpRequest();

      request.open("GET", urlPath, true);

      request.responseType = "arraybuffer";
      request.onload = function () {
        var audioData = request.response;

        audioCtx.decodeAudioData(
          audioData,
          function (buffer) {
            source.buffer = buffer;
            setArrayAudioBuffer(source.buffer);
            //source.connect(audioCtx.destination);
            //source.loop = true;
          },

          function (e) {
            console.log("Error with decoding audio data" + e.err);
          }
        );
      };
      request.send();
    }

    if (urlPath) {
      audioArrayBuffer();
    }
  }, [urlPath]);

  //Peaks.js Implementation
  useEffect(() => {
    if (arrayAudioBuffer) {
      const options = {
        containers: {
          overview: document.getElementById("overview-container"),
          zoomview: document.getElementById("zoomview-container"),
        },
        mediaElement: document.querySelector("audio"),
        webAudio: {
          audioBuffer: arrayAudioBuffer,
        },
        keyboard: true,
        emitCueEvents: true,
        height: 130,
        pointMarkerColor: "red",
        randomizeSegmentColor: false,
        showPlayheadTime: true,
        playheadColor: "red",
        playheadTextColor: "red",
        zoomLevels: [512, 1024, 2048, 4096],
        zoomWaveformColor: "rgba(0, 0, 0, 1)",
        segmentColor: "rgba(0, 0, 0, 1)",
      };

      Peaks.init(options, async function (err, peaksInstance) {
        //console.log("peaks", peaksInstance);
        setAddedSegments([]);
        setMergeSegment([]);
        setFormErrors([]);
        setAllPeaks(peaksInstance);
        
        const Jsonparse = readJson && JSON.parse(readJson);
        //console.log("Jsonparse", Jsonparse);
        if (Jsonparse) {
          Jsonparse.map((jsondata) => {
            //console.log("jsondata.isTranscribe",jsondata.isTranscribe)
            peaksInstance.segments.add({
              startTime: parseFloat(jsondata.startTime),
              endTime: parseFloat(jsondata.endTime),
              labelText: jsondata.segmentData,
              editable: false,
              speakerId: jsondata.speakerId,
              gender: jsondata.gender,
              agegroup: jsondata.agegroup,
              sentiment: jsondata.sentiment!=""?jsondata.sentiment:"NE",
              speakerDetails: jsondata.speakerDetails,
              isChecked: jsondata.isChecked,
              //isTranscribe: jsondata.isTranscribe,
              //color: jsondata.isTranscribe ? "#ff0000" : "#00ff00",
            });
            peaksInstance.points.add({
              time: parseFloat(jsondata.endTime),
              editable: false,
            });
          });
        } else {
          await new Promise((resolve) => {
            peaksInstance.segments.add({
              startTime: 0,
              endTime: isNaN(peaksInstance.player.getDuration()) ? datalist.fileDurationSecs : peaksInstance.player.getDuration(),
              labelText: autoTranscription ? autoTranscription : "",
              editable: false,
              speakerId: "",
              sentiment:"NE",
              //isTranscribe: false
            });
            resolve(); // Resolve the promise after segment addition
          })

          let segmentarray = [];
            peaksInstance.segments._segments.map((sdata, index) => {
              //const TotalSeconds = (sdata._endTime - sdata._startTime).toFixed(3);
                let segmentdata = {
                  id: sdata._id,
                  startTime: sdata._startTime,
                  segmentData: encodeURI((sdata._labelText).trim().replace(/\n/g, '')),
                  endTime: sdata._endTime,
                  speakerId: sdata.speakerId,
                  //isChecked: sdata.isChecked,
                  //isTranscribe: sdata.isTranscribe,
                  segmentNo: index + 1
                };
                segmentarray.push(segmentdata);
                //console.log("segmentarray", segmentarray)
          
            });
            
            const url = `${process.env.REACT_APP_URL}/transcription/editor/saveggrtjsondata`;
        
            const headers = reqHeader(true, true, true);
            //console.log("header", headers);
            const body = {
              segmentarray,
              FileId: props.match.params.id,
              stage: datalist.stage,
        
            };
            //console.log("body", body);
            const config = { headers };
        
            const { data } = await axios.put(url, body, config);
        
            if (data.success) {
              toast.success(data.msg);
            }
        }

        addnewSegmant(peaksInstance);
       
        // peaksInstance.zoom.setZoom({ scale: 512 }); // zoom level is now 4096
        // peaksInstance.zoom.setZoom({ seconds: 5.0 }); // zoom level is now 4096
        //peaksInstance.zoom.setZoom(0); // zoom level is now 4096

        peaksInstance.on("segments.click", (segment) => {
          // const point = peaksInstance.player.getCurrentTime()
          // // console.log("getCurrentSegment point", point)
          // // const seek = peaksInstance.player.seek(point)
          // //console.log("getCurrentSegment seek", seek)
          
          peaksInstance.segments._segments.map((seg) =>
            seg.update({ color: "rgba(0, 0, 0, 1)" })
          );
          let lastseg = peaksInstance.segments._segments.length;
          peaksInstance.segments._segments[parseInt(lastseg) - 1].update({
            color: "rgba(0, 0, 0, 1)",
          });
          segment.update({ color: "#4d4cac" });
          setMergeSegment([segment]);
        });

        //Change audio url code
          let fname=datalist.fileName;
          console.log("fname...",fname);
          if(fname)
          {
          let randomn=Math.random();
          let splitpath=fname.split(".");
          let newpath=splitpath[0]+randomn;
          
          let newfp=urlPath.replace(splitpath[0],newpath);
          console.log("new Path is..",newfp);
          setUrlPath(newfp);
          }
      });
    }
  }, [arrayAudioBuffer]);

  //Disable Right click, F12, ctrl+shift+I, ctrl+shift+J
  useEffect(()=>{
    // disable right click
    document.addEventListener('contextmenu', event => event.preventDefault());
 
    document.onkeydown = function (e) {
 
        // disable F12 key
        if(e.keyCode == 123) {
            return false;
        }
 
        // disable I key
        if(e.ctrlKey && e.shiftKey && e.keyCode == 73){
            return false;
        }
 
        // disable J key
        if(e.ctrlKey && e.shiftKey && e.keyCode == 74) {
            return false;
        }
 
        // disable U key
        if(e.ctrlKey && e.keyCode == 85) {
            return false;
        }
    }
  },[])
  //Add New Segment
  const addnewSegmant = (peaksInstance) => {
    //
    var pk = { pt: peaksInstance };
    var wv = { nw: pk.pt };
    var pnts = wv.nw.points["_points"];
    var len = pnts.length;
    var lastPonint = len > 0 ? pnts[len - 1]["_time"] : 0;

    peaksInstance.points.add({
      time: peaksInstance.player.getCurrentTime(),
      editable: false,
    });

    let endtimearray = [];

    let curstarttime = peaksInstance.player.getCurrentTime();
    let lastsegstarttime = 0;
    let lastsegendtime = 0;

    let currentendtime = 0;
    let prevsegendtime = 0;

    //console.log("currentendtime", currentendtime);
    endtimearray = peaksInstance.segments._segments.map((et) => {
      //console.log("endtimevalues", et);
      if (et._startTime > lastsegstarttime) {
        lastsegstarttime = et._startTime;
      }
      if (et._endTime > lastsegendtime) {
        lastsegendtime = et._endTime;
      }

      if (et._endTime > curstarttime) return et._endTime;
    });

    if (
      peaksInstance.player.getCurrentTime() > 0 &&
      parseFloat(peaksInstance.player.getCurrentTime()).toFixed(2) !=
        parseFloat(lastPonint).toFixed(2)
    ) {
      if (peaksInstance.player.getCurrentTime() < lastsegendtime) {
        //console.log("Inside Loop");
        for (let i = 0; i < peaksInstance.segments._segments.length; i++) {
          //let maxlen = peaksInstance.segments._segments.length;
          //console.log("maxlen..", maxlen);
          if (peaksInstance.segments._segments[i]._endTime > curstarttime) {
            currentendtime = peaksInstance.segments._segments[i]._endTime;
            peaksInstance.segments._segments[i]._endTime =
              peaksInstance.player.getCurrentTime();
            //lastPonint = lastsegendtime;
            peaksInstance.segments.add({
              startTime: peaksInstance.player.getCurrentTime(),
              endTime: currentendtime,
              labelText: "",
              editable: false,
              speakerId: "",
              sentiment:"NE",
              //isTranscribe: false
            });
            break;
          }
          if (peaksInstance.segments._segments[i]._endTime == curstarttime) {
            //alert("Please select Wave form to create a new segment.");
            break;
          }
        }
      } else {
        //debugger;
        lastPonint = lastsegendtime;
        //console.log("lastPonint..", lastPonint);
        if(lastPonint != peaksInstance.player.getCurrentTime()){
          peaksInstance.segments.add({
            startTime: lastPonint,
            endTime: peaksInstance.player.getCurrentTime(),
            labelText: "",
            editable: false,
            speakerId: "",
            sentiment:"NE",
            //isTranscribe: false
          });
        }
      }
    }

    peaksInstance.segments._segments = peaksInstance.segments._segments.sort(
      (a, b) => parseFloat(a._startTime) - parseFloat(b._startTime)
    );
    renderSegments(peaksInstance);
  };

  //Render Segment
  const renderSegments = (peaksInstance) => {
    peaksInstance.segments._segments = peaksInstance.segments._segments.sort(
      (a, b) => parseFloat(a._startTime) - parseFloat(b._startTime)
    );
    peaksInstance.segments._segments.map((seg) =>
      seg.update({ color: "rgba(0, 0, 0, 1)" })
    );
    let lastseg = peaksInstance.segments._segments.length;
    if (lastseg != 0) {
      peaksInstance.segments._segments[parseInt(lastseg) - 1].update({
        color: "rgba(0, 0, 0, 1)",
      });
    }

    let allSegments = peaksInstance.segments.getSegments();
    setAddedSegments([...allSegments]);
  };


  //Update Segment
  const updateSegment = (item, id, e) => {
    const currentSegment = allPeaks.segments.getSegment(id);
    let objValue = Object.values(item);
    let objKey = Object.keys(item);
    objValue = objValue[0];
    objKey = objKey[0];
    if (
      objKey === "startTime" &&
      (objValue < 0 || objValue >= currentSegment.endTime || isNaN(objValue))
    ) {
      return null;
    } else if (
      objKey === "endTime" &&
      (objValue < 0 || objValue <= currentSegment.startTime || isNaN(objValue))
    ) {
      return null;
    }

    if (currentSegment && Object.values(item)) {
      currentSegment.update(item);
      let allSegments = allPeaks.segments.getSegments();
      setAddedSegments([...allSegments]);
    }
    //console.log(addedSegments);
  };

  //Loop Segment
  const getCurrentSegment = (e, id) => {

    let selectedSegment = allPeaks.segments.getSegment(id);
    let startTime = selectedSegment.startTime;
    allPeaks.player.seek(startTime)
    allPeaks.segments._segments.map((seg) =>
      seg.update({ color: "rgba(0, 0, 0, 1)" })
    );

    selectedSegment.update({ color: "#4d4cac" });

    setMergeSegment([selectedSegment]);
    //setSelectSegment([selectedSegment]);
  };

  //Merge Segment
  const getMergeSegment = (id) => {
    if (id == "peaks.segment.0") {
      alert("Segment 0 can not be merged.");
    } else if (id.length > 0 && id != null && id != undefined) {
      let mergeSegment = allPeaks.segments.getSegment(id);

      let curendtime = mergeSegment._endTime;
      let cursttime = mergeSegment._startTime;

      for (let i = 0; i < allPeaks.segments._segments.length; i++) {
        if (allPeaks.segments._segments[i]._endTime == curendtime) {
          let lbltxtcur = allPeaks.segments._segments[i]._labelText;
          let lbltxtprev = allPeaks.segments._segments[i - 1]._labelText;
          lbltxtprev = lbltxtprev + " " + lbltxtcur;

          allPeaks.segments._segments[i - 1]._endTime = curendtime;
          allPeaks.segments._segments[i - 1]._labelText = lbltxtprev;
          allPeaks.segments._segments.splice(i, 1);
          // allPeaks.segments.update({
          //   color: "rgba(94,129,244, 0.8)",
          // });
          //console.log("Segments after splicing..", allPeaks._segments);
          allPeaks.points.removeByTime(cursttime);
          setMergeSegment([]);
          break;
        }
      }

      allPeaks.segments._segments = allPeaks.segments._segments.sort(
        (a, b) => parseFloat(a._startTime) - parseFloat(b._startTime)
      );
      console.log("merged allpeaks before merged", allPeaks)
      renderSegments(allPeaks);
      console.log("merged allpeaks after merged", allPeaks)
      toast.success("Segment Merged successfully!");
    } else {
      //alert("Please select the segment to be merged.");
    }
  };

  //Handle Segment Error
  const handleSegmentErrors = (item, seconds, spkid, id, e) => {
    e.preventDefault();
    //console.log("item.labelText, seconds, spkid, segmentIstranscribed", item.labelText, seconds, spkid, segmentIstranscribe)
    let lan= datalist && datalist.srcLang;
    // console.log("lan", lan)

    if (lan === "Hindi" || lan === "Bengali" || lan === "Kurukh" || lan === "Khortha" || lan === "Bhojpuri" || lan === "Magahi" || lan === "Surjapuri" || lan === "Maithili" || lan === "Bhili" || lan === "Gondi" || lan === "Rajasthani" || lan === "Marwari" || lan === "Angika" || lan === "Garhwali" || lan === "Bhatri" || lan === "Santali" || lan === "Awadhi" || lan === "Sadri" || lan === "Bundeli" || lan === "Chattisgarhi" || lan === "Surgujia" || lan === "Dorli" || lan === "Dhurwa" || lan === "Thethi" || lan === "Bajjika" ) {
      // console.log("inside lan")
      let str = item.labelText.replaceAll(".", "।").replaceAll("|", "।");

      updateSegment({ labelText: str }, id)
    }else{
      console.log("foreign text")
     }

     const fixFormatting = (text) => {
      return text
          .replace(/\s*{\s*/g, " {")  // Ensure space before `{` and remove inside spaces
          .replace(/\s*}\s*/g, "} ")  // Ensure space after `}` and remove inside spaces
          .replace(/\s*<\s*/g, " <")  // Ensure space before `<` and remove inside spaces
          .replace(/\s*>/g, "> ")      // Ensure space after `>` and remove inside spaces
          .replace(/\s*\[\s*/g, " [")  // Ensure space before `[` and remove inside spaces
          .replace(/\s*\]\s*/g, "] ")  // Ensure space after `]` and remove inside spaces
          .replace(/\s+/g, " ")       // Remove multiple spaces
          .replace(/\s([,.])/g, "$1") // Remove space before punctuation
          .trim();                    // Trim spaces from start and end
    };

    let correctedText = fixFormatting(item.labelText);
    //console.log(correctedText);
    updateSegment({ labelText: correctedText }, id)
  
    //console.log("lan..",lan);
    let errors = validate(item.labelText, seconds, spkid, lan);
    errors.id = id;
    //console.log("validation Error", errors);
    setSegmentError(errors);
  };

  //save data to Json File
  const saveJson = async (e) => {
    console.log("inside savejson")
    e.preventDefault();
    //console.log("inside save json", allPeaks.segments._segments);
    //console.log("datalist", datalist);
    //setFormErrors([]);
    let segmentarray = [];

    allPeaks.segments._segments.map((sdata, index) => {
      const TotalSeconds = (sdata._endTime - sdata._startTime).toFixed(3);
        let segmentdata = {
          id: sdata._id,
          startTime: sdata._startTime,
          segmentData: encodeURI((sdata._labelText).trim().replace(/\n/g, '')),
          endTime: sdata._endTime,
          speakerId: sdata.speakerId,
          isChecked: sdata.isChecked,
          //isTranscribe: sdata.isTranscribe,
          segmentNo: index + 1
        };
        segmentarray.push(segmentdata);
        //console.log("segmentarray", segmentarray)
   
    });
    
    const url = `${process.env.REACT_APP_URL}/transcription/editor/saveggrtjsondata`;

    const headers = reqHeader(true, true, true);
    //console.log("header", headers);
    const body = {
      segmentarray,
      FileId: props.match.params.id,
      stage: datalist.stage,

    };
    //console.log("body", body);
    const config = { headers };

    const { data } = await axios.put(url, body, config);

    if (data.success) {
      toast.success(data.msg);
    }
  };

  //Submit file
  const submitForm = async (e) => {
    e.preventDefault();
    saveJson(e);
   
    //console.log("datalist.stage", datalist.stage);
    //setFormErrors([]);
    if(datalist.stage == "Transcription Assigned" || datalist.stage == "Transcription"){
      let segmentarray = [];
      //debugger;
      allPeaks.segments._segments.map((sdata, index) => {
        const TotalSeconds = (sdata._endTime - sdata._startTime).toFixed(3);
          let segmentdata = {
            id: sdata._id,
            startTime: sdata._startTime,
            segmentData: encodeURI((sdata._labelText).trim().replace(/\n/g, '')),
            endTime: sdata._endTime,
            speakerId: sdata.speakerId,
            segmentNo: index + 1,
            isChecked:sdata.isChecked,
            //isTranscribe: sdata.isTranscribe
          };
          segmentarray.push(segmentdata);
        
        //console.log("segmentarray submit", segmentarray)
        let lan = datalist && datalist.srcLang;
        let segment = validate(sdata._labelText, TotalSeconds, sdata.speakerId, lan);
        let segmentErrorDetails = {
          id: sdata._id,
          segmenterrors: segment.segmentdata,
        };
        formErrors.push(segmentErrorDetails);
      });

      //console.log("formErrors", formErrors)
      setFormErrors(formErrors);
 
      //Set flag for show error in drawer list
      for (let i = 0; i < formErrors.length; i++) {
        //console.log("filter", formErrors[i].segmenterrors.length);
        if (formErrors[i].segmenterrors.length > 0) {
          setFlag(true);
          toast.error("Please resolve all segment errors before submit");
          setPane({ isPaneOpen: true })
          break;
        } else {
          setFlag(false);
        }

        if (i == formErrors.length - 1) {
          let childwindow = "";
          //if(userType == "Manager"){
            if (!flag) {
              const result = await Confirm(
                "Make sure you have Saved your data before submitting. Once Submitted you will not be able to open the file again."
              );
              if (result) {
                //console.log("Inside submit flag false..");
              const url = `${process.env.REACT_APP_URL}/transcription/editor/submitggrteditorform`;
  
              const headers = reqHeader(true, true, true);
              //debugger;
             //console.log("segmentarray iside submitForm...", segmentarray);
              const body = {
                segmentarray,
                FileId: id,
                stage: datalist.stage,
              };
              //console.log("body", body);
              const config = { headers };
  
              const { data } = await axios.put(url, body, config);
              //console.log("Submitted data", data);
              if (data.success) {
                toast.success(data.msg);
                //history.push("/dashboard");
                //window.location.href("/dashboard")
                window.close();
              }
            }
          }
          //}

            
      }
    }

     
    }else if(datalist.stage == "Proof Reading Assigned" || datalist.stage == "Proof Reading"){

      let segmentarray = [];
      //debugger;
      allPeaks.segments._segments.map((sdata, index) => {
        const TotalSeconds = (sdata._endTime - sdata._startTime).toFixed(3);
          let segmentdata = {
            id: sdata._id,
            startTime: sdata._startTime,
            segmentData: encodeURI((sdata._labelText).trim()),
            endTime: sdata._endTime,
            speakerId: sdata.speakerId,
            segmentNo: index + 1,
            isChecked:sdata.isChecked,
            //isTranscribe: sdata.isTranscribe
          };
          segmentarray.push(segmentdata);
        
       // console.log("segmentarray submit", segmentarray)
        let lan = datalist && datalist.srcLang;
        let segment = validate(sdata._labelText, TotalSeconds, sdata.speakerId, lan);
        let segmentErrorDetails = {
          id: sdata._id,
          segmenterrors: segment.segmentdata,
        };
        formErrors.push(segmentErrorDetails);
      });

      //console.log("formErrors", formErrors)
      setFormErrors(formErrors);
 
      //Set flag for show error in drawer list
      for (let i = 0; i < formErrors.length; i++) {
        //console.log("filter", formErrors[i].segmenterrors.length);
        if (formErrors[i].segmenterrors.length > 0) {
          setFlag(true);
          toast.error("Please resolve all segment errors before submit");
          setPane({ isPaneOpen: true })
          break;
        } else {
          setFlag(false);
        }

        if (i == formErrors.length - 1) {
          //console.log("flagg", flag)
          const result = await CustomDialog(
            <GGRTPH2ProofReadSubmit allPeaks={allPeaks} datalist={datalist} id={id} />,
            { title: "Proofread Submit", showCloseIcon: true }
          );
            
      }
    }
  }
};


  const handleKeyBoardEvents = (key, id, e) => {
    //
    e.preventDefault();
    //console.log("key, id", key, id);
    let selectedSegment = allPeaks.segments.getSegment(id);
    let Segment_EndTime = selectedSegment.endTime
   
    if (key == "ctrl+s") {
      saveJson(e);
      //setPane({ isPaneOpen: true });
    }
    // else if (key == "shift+tab") {
    //   allPeaks.player.playSegment(selectedSegment);
    // }
    else if (key == "tab") {
      // debugger;
      // if (isplay == false) {
      //   allPeaks.player.play();
      //   setIsPlay(true);
      // } else if(isplay == true) {
      //   allPeaks.player.pause();
      //   setIsPlay(false);
      // } 
      allPeaks.player.playSegment(selectedSegment);
    }
    key = "";
  };

  //React-textarea-autocomplete code
  const Item = ({ entity: { name, string } }) => <div>{`${string}`}</div>;

  //Handle speaker selection on row
  const onChangehandleSpeaker = (id, speakerId) => {
    let result = rowAdded.find((obj) => {
      return obj.speakerid === speakerId;
    });
    //console.log("result", result);
    const currentSegment = allPeaks.segments.getSegment(id);
    if (result === undefined && speakerId == "NS") {
      currentSegment &&
        currentSegment.update({
          speakerId: "NS",
        });
    }else if(result === undefined && speakerId == "defaultvalue"){
      currentSegment &&
      currentSegment.update({
        speakerId: "",
      });
    } else {
      currentSegment &&
        currentSegment.update({
          speakerId: result.speakerid,
        });
    }

    let allSegments = allPeaks.segments.getSegments();
    console.log("allSegments change speaker id", allSegments);
    setAddedSegments([...allSegments]);
  };

  const fetchSuggestions = async (newWord) => {

    try {
      if (!newWord) return [];

      const url = `${process.env.REACT_APP_URL}/transcription/editor/engspellchecker`;
      const headers = reqHeader(true, true, true);
      const body = {
        word : newWord,
  
      };
      //console.log("body", body);
      const config = { headers };

      //console.log("body", body)

        const response = await axios.post(url, body, config);
        //console.log("response", response)
        if (!response.data.isCorrect) {
          return response.data.suggestions.map((suggestion) => ({ text: suggestion })) || [];
      } 

    } catch (error) {
        console.error("Error checking spelling:", error);
    }
    return [];
};


  // const exportTotxtFile = () => {
  //   let textPrintArray = [];
  //   allPeaks.segments._segments.map((sdata) => {
  //     let segmentIdandData = {
  //       id: sdata._id,
  //       segmentData: sdata._labelText,
  //     };
  //     textPrintArray.push(segmentIdandData);
  //   });

  //   const element = document.createElement("a");
  //   let result = [];

  //   for (var x in textPrintArray) {
  //     if (textPrintArray.hasOwnProperty(x))
  //       result.push(
  //         textPrintArray[x].id.replace("peaks.segment.", "SegmentID:") +
  //           "\t" +
  //           textPrintArray[x].segmentData +
  //           "\n"
  //       );
  //   }
  //   const file = new Blob(result, { type: "text/plain;charset=utf-8" });
  //   element.href = URL.createObjectURL(file);
  //   element.download = "Export.txt";
  //   document.body.appendChild(element);
  //   element.click();
  // };


 //Proofread service
  const handleOnChange = (e) => {
    const {name, checked} = e.target;
    console.log("name, checked", name, checked)
    const currentSegment = allPeaks.segments.getSegment(name);
    //console.log("currentSegment", currentSegment.id)
    if(currentSegment.id === name){
      currentSegment.update({ isChecked: checked })
      let allSegments = allPeaks.segments.getSegments();
      console.log("allSegments", allSegments);
      setAddedSegments([...allSegments]);
    }
  };

  //Segment Content in textarea
  const SegmentContent = addedSegments.map((segments, index) => {
    //console.log("segments contents", segments)

    // const startTimeFormatted = segments.startTime;
    // const endTimeFormatted = segments.endTime;
    const startTimeFormatted = new Date(segments.startTime * 1000)
    .toISOString()
    .substr(11, 11);

    const endTimeFormatted = new Date(segments.endTime * 1000)
    .toISOString()
    .substr(11, 11);

    const TotalSeconds = (segments.endTime - segments.startTime).toFixed(3);
    let segmentIndex = addedSegments.findIndex((element, index) => {
      if (element._id === segments._id) {
        return true;
      }
    });
    
    return (
      <div key={index+1}>

        <KeyboardEventHandler
          handleKeys={["ctrl+s", "tab"]}
          //handleEventType={"keyup"}
          //handleFocusableElements
          onKeyEvent={(key, e) => handleKeyBoardEvents(key, segments.id, e)}>
          <div className="segment-details">
            <div
              className={
                "seg-info d-flex" +
                " " +
                (segmentError.segmentdata && segmentError.id == segments.id
                  ? "errorWrap"
                  : "")
              }
              key={segments.id}>
              <select
                className="speaker-selection"
                key={segments.id}
                value={segments.speakerId}
                onChange={(e) =>
                  onChangehandleSpeaker(segments.id, e.target.value)
                }>
                <option value="defaultvalue" disabled>Select Speaker</option>
                {/* <option value="NS">NO SPEAKER</option> */}
                {rowAdded.map((item) => {
                  //console.log("speaker item", item);
                  return (
                    <option disabled key={item.speakerid} value={item.speakerid}>
                      {item.speakerid}
                    </option>
                  );
                })}
              </select>
              <div>{`Segment ${segmentIndex + 1}`}</div>
              <div>
                {startTimeFormatted} - {endTimeFormatted}
              </div>
              <div>{TotalSeconds}</div>
              <div className="sentiment-section d-flex">
                <div className="status-container">
                  {segmentError.segmentdata != "" &&
                    segmentError.id == segments.id && <ErrorStatus />}
                  <div className="error-popup">
                    <div className="error-item">
                      {segmentError.segmentdata &&
                        segmentError.segmentdata.map((error, index) => {
                          if (segmentError.id == segments.id) {
                            return (
                              <strong key={index+1}>
                                {error.SegmentDataEmpty}
                                {error.TwentySecondError}
                                {/* {error.FourSecondStandaloneTags} */}
                                {error.numericValue}
                                {error.SpecialCharacters}
                                {error.MathSymbols}
                                {error.Okaystring}
                                {error.openCloseMismatch}
                                {error.EmptyAngularTags}
                                {error.AngularMismatch}
                                {error.InvalidAngularTag}
                                {error.InvalidFILTag}
                                {error.InvalidSILTag}
                                {error.InvalidUNINTag}
                                {error.SentimentEmpty}
                                {error.speakerIdEmpty}
                                {error.genderEmpty}
                                {error.agegroupEmpty}
                                {error.InvalidFirstLetter}
                                {error.InvalidBlankTag}
                                {error.SpaceBeforePeriod}
                                {error.Invalidoverlaptag}
                                {error.CurlyMismatch}
                                {error.InvalidCurlyBracket}
                                {error.InvalidEnglishWords}
                                {error.MultipleFullStop}
                               {/* {error.InvalidCurlyBrackets} */}
                                {error.invalidLastChar}
                                {/* {error.wordCount} */}
                              </strong>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
                  {(datalist.stage == "Proof Reading Assigned" || datalist.stage == "Proof Reading") ?
                  <div>
                    <input
                      type="checkbox"
                      id={segments.id}
                      name={segments.id}
                      checked={segments?.isChecked || false}
                      onChange={handleOnChange}
                    />
                  </div>:""}
              </div>
            </div>
            <div className="seg-summary d-flex">
               {/* {console.log("segments.isTranscribe editor", segments.isTranscribe)} */}
              <ReactTextareaAutocomplete
                className="segmentTextarea"
                //className={datalist.tgtLang == "Urdu" ? "segmentTextarea segmentTextareaRtl" : "segmentTextarea"}
                //disabled={!segments.isTranscribe}
                //style={{background : segments.isTranscribe ? "#FFFFFF" : "#D3D3D3"}}
                // color={segments.isTranscribe ? "#FFFFFF" : "#D3D3D3"}
                key={segments.id}
                //charset="ISO-8859-1"
                value={segments.labelText}
                onChange={(e) =>
                  {updateSegment({ labelText: e.target.value }, segments.id);
                  //setText(e.target.value)
                }}
                onClick={(e) => getCurrentSegment(e, segments.id)}
                onBlur={(e) => {
                  handleSegmentErrors(
                    { labelText: e.target.value },
                    TotalSeconds,
                    segments.speakerId,
                    //segments.isTranscribe,
                    segments.id,
                    e,
                  );
                  // runSpeller(
                  //   { labelText: e.target.value },
                  //   segments.id,
                  //   e
                  // ) 
                } 
                }
              
                loadingComponent={() => <span>Loading</span>}
                minChar={0}
                trigger={{
                  "<": {
                    dataProvider: (token) => {
                    // console.log("token is ", token);
                    
                     return angularTags.slice(0, angularTags.length).filter((word) =>
                        word.string.toUpperCase().
                        includes(token.toUpperCase())
                      );
                    },
                    component: Item,
                    output: (item, trigger) => item.string,
                  },
                  "[": {
                    dataProvider: (token) => {
                      return slashTags.slice(0, slashTags.length).filter((word) =>
                      word.string.toUpperCase().includes(token.toUpperCase())
                    );
                    },
                    component: Item,
                    output: (item, trigger) => item.string,
                  },
                  "{": {
                        dataProvider: (token) => fetchSuggestions(token.trim()),
                        component: ({ entity }) => <div>{entity.text}</div>,
                        output: (item) => "{" + item.text + "}",
                    },
                }}
              />
            </div>
              {/* {spelltext.map((spellText, index)=>{
                if(spellText.id == segments.id){
                  return<span key={index+1} style={{backgroundColor:"yellow"}}>{spellText.getword}{index+1 ? ", ": ""}</span>
                }
              })} */}
             
          </div>
        </KeyboardEventHandler>
      </div>
    );
  });

  const SlidePane = (
    <SlidingPane
      className="some-custom-class"
      overlayClassName="some-custom-overlay-class"
      isOpen={pane.isPaneOpen}
      width="400px"
      title="Errors"
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        setPane({ isPaneOpen: false });
        setFormErrors([]);
        setFlag();
      }}>
      <div className="errortitle">
        <ErrorIcon /> Please solve the following errors.
      </div>

      {flag == true ? (
        formErrors &&
        formErrors.map((errors) => {
          // console.log("flag", flag)
          let splitSegmentId = errors.id.split(".");
          let getSegmentId = splitSegmentId[2];
          let segmentIndex = addedSegments.findIndex((element, index) => {
           // console.log("element._id, errors.id", errors.id, getSegmentId)
            if (element._id === errors.id) {
              return true;
            }
          });
          //console.log("segmentIndex", segmentIndex)
          return errors.segmenterrors.map((error, index) => {
            return (
              <div
                className="errorItem"
                key={`Segment ${parseInt(getSegmentId) + 1}_${index + 1} `}>
                <div className="twoWayArrow"></div>
                {`Segment ${segmentIndex + 1} `}
                {error.SegmentDataEmpty}
                {error.TwentySecondError}
                {/* {error.FourSecondStandaloneTags} */}
                {error.numericValue}
                {error.SpecialCharacters}
                {error.MathSymbols}
                {error.Okaystring}
                {error.openCloseMismatch}
                {error.EmptyAngularTags}
                {error.AngularMismatch}
                {error.InvalidAngularTag}
                {error.InvalidFILTag}
                {error.InvalidSILTag}
                {error.InvalidUNINTag}
                {error.SentimentEmpty}
                {error.speakerIdEmpty}
                {error.genderEmpty}
                {error.agegroupEmpty}
                {error.InvalidFirstLetter}
                {error.InvalidBlankTag}
                {error.SpaceBeforePeriod}
                {error.Invalidoverlaptag}
                {error.CurlyMismatch}
                {error.InvalidCurlyBracket}
                {error.InvalidEnglishWords}
                {error.MultipleFullStop}
            {/* {error.InvalidCurlyBrackets} */}
                {error.invalidLastChar}
                {/* {error.wordCount} */}
              </div>
            );
          });
        })
      ) : (
        <div>Thanks for saving. You have no validation errors.</div>
      )}
    </SlidingPane>
  );

  return !arrayAudioBuffer ? (<Spinner />) : (
    <>
      {/* <RightClickMenu /> */}
      <div className="container">
        {/* <ToastContainer position="top-left" /> */}
        <div className="transcriber-container">
          <div className="transcriber-header d-flex">
            <div className="logo">
              <img src={Logo} alt="Logo" className="" height="40" />
            </div>
            <UserProfile />
          </div>
          <div className="trancriber-detail d-flex">
            <ul className="transcriber-info">
              <li>
                <label>File Name</label>
                <div>{datalist.fileName}</div>
              </li>
              <li>
                <label>Transcription Time</label>
                <div>{datalist.fileDuration}</div>
              </li>
              <li>
                <label>Language</label>
                <div>{datalist.tgtLang}</div>
              </li>

              <li>
                <label>ETA</label>
                <div>
                  {datalist.ETA && format(new Date(datalist.ETA), "dd/MM/yyyy")}
                </div>
              </li>
            </ul>

            <div>
              <button disabled
                onClick={async () => {
                  await CustomDialog(<GuidelinesContent />, {
                    title: "Megdap Guidelines",
                    showCloseIcon: true,
                  });
                }}
                className="btn guideline">
                Megdap Guidelines
              </button>
              <button
                onClick={async () => {
                  await CustomDialog(<KeyShortcutContents />, {
                    title: "Keyboard Shortcuts",
                    showCloseIcon: true,
                  });
                }}
                className="btn shortcut">
                Keyboard Shortcuts
              </button>
            </div>
          </div>
          <div id="stickywave" className="transcriber-waves">
            <div className="transWaveWrap">
              <div id="peaks-container">
                <div id="zoomview-container"></div>
                {/* <div id="overview-container"></div> */}
              </div>
            </div>
            <div className="audioWrap">
            <ToastContainer position="top-left" />
              {urlPath && (
                <audio
                  className="transAudioWrap"
                  //style={{display:"none"}}
                  id="audio"
                  controls
                  controlsList="nodownload">
                  <source src={urlPath} type="audio/wav" />
                </audio>
              )}
            </div>
            {/* <div> {notify} </div> */}
          </div>
        </div>
        <div id="segmentwrap" className="segment-container">
          <div className="tableWrap projectTableWrap block">
            <div className="segment-header d-flex">
              <div>Title</div>
              <div className="action-btn-group d-flex">
                {/*Add New Segment Button*/}
                {/* <button onClick={(e) => addnewSegmant(allPeaks)}>
                  Add Segment
                </button> */}
                {/*Merge Segment Button*/}
                {/* {!mergeSegment.length || addedSegments.length <= 1 ? (
                  <button
                    disabled={
                      !mergeSegment.length || addedSegments.length <= 1
                    }>
                    Merge Segment
                  </button>
                ) : (
                  mergeSegment.map((mergeSegment) => {
                    return (
                      <button
                        key={mergeSegment.id}
                        onClick={(e) => getMergeSegment(mergeSegment.id)}>
                        Merge Segment
                      </button>
                    );
                  })
                )} */}
                {/*Save Button*/}
                <button
                  onMouseDown={(e) => {
                    saveJson(e);
                    // setPane({ isPaneOpen: true });
                  }}>
                  Save
                </button>
                {SlidePane}
                {/*Submit button*/}
                <button
                  onMouseDown={(e) => {
                    submitForm(e);
                    //setPane({ isPaneOpen: true });
                  }}>
                  Submit
                </button>
                {/*Export Text Button*/}
                {/* <button onClick={(e) => exportTotxtFile(allPeaks)}>
                  Export Text
                </button> */}
                {/* <button
                onClick={async () => {
                  await CustomDialog(<FindAndReplace allPeaks={allPeaks} />, {
                    title: "Find and Replace",
                    showCloseIcon: true,
                  });
                }}
                >
                Find and Replace
              </button> */}
                {/* <button onClick={(e) => runSpeller(allPeaks)}>
                  Run Speller
                </button> */}
                {/* <button onClick={(e) => AddStartTime(allPeaks)}>
                  Add start time
                </button> */}
              </div>
            </div>
            <div className="segment-data-wrap">
              {SegmentContent}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GGRTEditorPhase2;
